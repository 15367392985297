import { useDecisionAid } from "../../hooks/useDecisionAids";
import { useParams, Navigate, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { PageEditor } from "./PageEditor";
import { GeneralSettingsEditor } from "./GeneralSettingsEditor";
import { Alert, Button, ButtonGroup, Flex, Heading, Icon, TextField, ToggleButton, View } from "@aws-amplify/ui-react";
import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { SortableToggleMenuButton } from "../../components/SortableToggleMenuButton";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { MdOutlineAddCircleOutline, MdHome, MdSave, MdOpenInNew, MdContentCopy, MdFileDownload } from "react-icons/md";
import { DecisionAid } from "../../types/DecisionAid";
import { v4 as uuidv4 } from "uuid";
import { useDebouncedEffect } from "../../hooks/useDebouncedEffect";
import deepEqual from "deep-equal";
import { DecisionAidPage, DecisionAidPageUpdate } from "../../types/Page";
import { setDecisionAid } from "../../api/decisionAids";
import { useEffectOnce } from "usehooks-ts";
import { useCredentials } from "hooks/useCredentials";
import { IconLink } from "@codexteam/icons";
import { IconUnlink } from "@codexteam/icons";
import { Helmet } from "react-helmet-async";

interface Props {}

export const AidEditor: React.FC<Props> = () => {
  const { id } = useParams();
  const [loadedAid, error, overrideAid] = useDecisionAid(id || "");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const sensors = useSensors(useSensor(PointerSensor));

  const [aid, setAid] = useState<DecisionAid>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const credentials = useCredentials();

  const isMyDecisionAid = aid?.userId ? aid?.userId === credentials?.identityId : false;

  useEffect(() => {
    if (!hasChanges && aid !== loadedAid) {
      setHasChanges(true);
    }
  }, [aid, hasChanges, loadedAid]);

  //fixing whether there are changes to be saved
  useDebouncedEffect(
    () => {
      if (hasChanges && aid !== loadedAid && deepEqual(aid, loadedAid, { strict: true })) {
        setAid(loadedAid);
        setHasChanges(false);
      }
    },
    [aid],
    500
  );

  const saveAid = () => {
    if (aid && isMyDecisionAid && aid !== loadedAid && !isSaving) {
      setIsSaving(true);
      setDecisionAid(aid).then(() => {
        overrideAid && overrideAid(aid);
        setIsSaving(false);
      });
    }
  };

  function copyAid() {
    const id = uuidv4();
    if (aid) {
      setIsSaving(true);
      const newAid = {
        ...aid,
        id,
        userId: undefined,
        name: aid.name.replace(/ \(Copy (\d+)\)$|$/, (_, o) => ` (Copy ${(Number.parseInt(o) || 0) + 1})`),
      };
      setDecisionAid(newAid).then(() => {
        setIsSaving(false);
        navigate(`/editor/aid/${id}`);
      });
    }
  }

  //autosaving
  useDebouncedEffect(saveAid, [aid], 3000, () => {
    if (aid) setDecisionAid(aid);
  });

  //autosave when quit
  useEffectOnce(() => {
    return saveAid;
  });

  useEffect(() => {
    if (loadedAid) {
      setAid(loadedAid);
      setHasChanges(false);
    }
  }, [loadedAid]);

  const [newPageName, setNewPageName] = useState<string>("");

  const performSetAid = (setter: (current: DecisionAid) => DecisionAid) => {
    if (!isMyDecisionAid) return;
    setAid((cAid: DecisionAid | undefined) => {
      const current: DecisionAid = cAid === loadedAid ? structuredClone(loadedAid) : cAid;
      return setter(current);
    });
  };

  const addNewPage = () => {
    performSetAid((current) => ({
      ...current,
      pages: [
        ...current.pages,
        {
          type: "knowledge",
          id: uuidv4(),
          name: newPageName,
        },
      ],
    }));
    setNewPageName("");
  };

  const updateAid = (updates: Partial<Omit<DecisionAid, "id">>) => {
    performSetAid((current) => ({
      ...current,
      ...updates,
    }));
  };

  const updatePage = (id: string, updates: DecisionAidPageUpdate) => {
    performSetAid((current) => {
      return {
        ...current,
        pages: current.pages.map<DecisionAidPage>((page) => (page.id === id ? { ...page, ...updates } : page)),
      };
    });
  };

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over.id) {
      performSetAid((current: DecisionAid) => {
        const oldIndex = current.pages.findIndex((page) => page.id === active.id);
        const newIndex = current.pages.findIndex((page) => page.id === over.id);

        return {
          ...current,
          pages: arrayMove(current.pages, oldIndex, newIndex),
        };
      });
    }
  }

  function downloadAid() {
    const json = JSON.stringify({ ...aid, userId: undefined, id: undefined }, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = href;
    link.download = aid?.name + ".json";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }

  function deletePage(id: string) {
    performSetAid((current) => ({
      ...current,
      pages: current.pages.filter((page) => page.id !== id),
    }));
  }

  if (!id || error) {
    return <Navigate to="../" />;
  }

  if (!aid) {
    return <></>;
  }

  return (
    <Flex grow="1">
      <Helmet>
        <title>Decision Aid - {aid.title}</title>
      </Helmet>
      <Sidebar>
        <Heading level={2}>{aid.name}</Heading>
        {!isMyDecisionAid && (
          <Alert variation="warning" heading="This is not your Decision Aid" margin="0.5rem 0">
            Any changes you make will not be saved. Make a copy (below) to edit this Decision Aid.
          </Alert>
        )}
        <View margin="0.5rem 0">
          <ButtonGroup gap="0.5em" size="small" wrap="wrap" justifyContent="center">
            <Button gap="0.5em" onClick={() => navigate("../")}>
              <Icon as={MdHome} /> Dashboard
            </Button>
            <Button gap="0.5em" onClick={() => window.open(`/aid/${aid.id}`, "_blank")}>
              <Icon as={MdOpenInNew} /> View
            </Button>
            {isMyDecisionAid && (
              <Button gap="0.5em" isDisabled={!hasChanges} isLoading={isSaving} onClick={saveAid}>
                <Icon as={MdSave} /> {isSaving ? "Saving" : hasChanges ? "Save" : "Saved"}
              </Button>
            )}

            <Button gap="0.5em" isDisabled={isSaving} onClick={copyAid}>
              <Icon as={MdContentCopy} /> Make a Copy
            </Button>
            <Button gap="0.5em" isDisabled={isSaving} onClick={downloadAid}>
              <Icon as={MdFileDownload} /> Export to File
            </Button>
          </ButtonGroup>
        </View>
        <ToggleButton
          isPressed={pathname.indexOf("pages") < 0}
          isFullWidth
          variation="menu"
          onClick={() => navigate("")}
        >
          Basic Details
        </ToggleButton>
        <Heading level={3}>Pages</Heading>
        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
          <SortableContext items={aid.pages} strategy={verticalListSortingStrategy}>
            {aid.pages.map((page) => (
              <SortableToggleMenuButton
                key={page.id}
                onDelete={page.type === "knowledge" ? deletePage.bind(null, page.id) : undefined}
                id={page.id}
                name={page.name}
                onClick={() => navigate(`pages/${page.id}`)}
                active={pathname.indexOf(page.id) >= 0}
                isDisabled={page.type !== "knowledge"}
              />
            ))}
          </SortableContext>
        </DndContext>
        <View>
          <TextField
            label="Add Knowledge Page"
            placeholder="New Page Name"
            value={newPageName}
            onChange={(e) => setNewPageName(e.target.value)}
            outerEndComponent={
              <Button onClick={addNewPage} disabled={newPageName === ""}>
                <Icon as={MdOutlineAddCircleOutline} />
              </Button>
            }
          />
        </View>
      </Sidebar>
      <Main>
        <Routes>
          <Route
            path="pages/:pageid"
            element={<PageEditor onUpdateAid={updateAid} onUpdate={updatePage} aid={aid} />}
          />
          <Route index element={<GeneralSettingsEditor onUpdate={updateAid} aid={aid} />} />
        </Routes>

        <HiddenIcon dangerouslySetInnerHTML={{ __html: IconLink.slice(0, 4) + ' id="link" ' + IconLink.slice(4) }} />
        <HiddenIcon
          dangerouslySetInnerHTML={{ __html: IconUnlink.slice(0, 4) + ' id="unlink" ' + IconUnlink.slice(4) }}
        />
      </Main>
    </Flex>
  );
};

const HiddenIcon = styled.span`
  position: absolute;
  left: -100%;
  top: -100%;
  opacity: 0;
`;

const Sidebar = styled(View)`
  min-width: 350px;
  max-width: 350px;
  padding: 1em;
`;

const Main = styled(View)`
  flex-grow: 1;
  padding: 1em;
`;
