import { useEffect, useState } from "react";

export const useFetchData = <TOutput>(
  fetcher: () => Promise<TOutput>
): [TOutput?, Error?, ((newData: TOutput) => void)?] => {
  const [data, setData] = useState<TOutput>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    let ignore = false;

    async function doFetch() {
      try {
        const resp = await fetcher();

        if (!ignore) {
          setData(resp);
        }
      } catch (error) {
        setError(error as Error);
      }
    }

    doFetch();

    return () => {
      ignore = true;
    };
  }, [fetcher]);

  return [data, error, setData];
};
