import { Alert, Heading, TabItem, Tabs, TextField, View } from "@aws-amplify/ui-react";
import { PageEditorProps } from ".";
import { DecisionPage } from "../../../types/Page";
import { EditorBlock } from "../../../components/editor";
import { ReplacementFactory } from "../../../components/editor/tools/Replacements/ReplacementFactory";
import { OutputData } from "@editorjs/editorjs";
import { DecisionReplacements } from "../../../components/editor/tools/Replacements/DecisionReplacements";
import styled from "@emotion/styled";
import Paragraph from "@editorjs/paragraph";
import { INLINE_TOOLS } from "components/editor/EditorTools";

interface ReflectionBlock {
  menu: string;
  title: string;
  data: keyof DecisionPage;
  info: string;
}

const ReflectionBlocks: Array<ReflectionBlock> = [
  {
    menu: "Aligned",
    title: "Decision aligned with user values",
    data: "reflectionAligned",
    info: "This is the text that will appear when a user selects the option that aligns with their stated values",
  },
  {
    menu: "Not Aligned",
    title: "Decision not aligned with user values",
    data: "reflectionNotAligned",
    info: "This is the text that will appear when a user selects the option that does not align with their stated values",
  },
  {
    menu: "Unsure",
    title: "Unsure with unbalanced values",
    data: "reflectionUnsure",
    info: "This is the text that will appear when a user responds that they are unsure, but also have values that align with a particular option",
  },
  {
    menu: "Balanced (1)",
    title: "Decision with balanced values",
    data: "reflectionBalanced",
    info: "This is the text that will appear when a user selects an option, even though their values are 'balanced' across both options.",
  },
  {
    menu: "Balanced (2)",
    title: "Unsure with balanced values",
    data: "reflectionUnsureAndBalanced",
    info: "This is the text that will appear when a user is unsure, and their values are 'balanced' across both options. ",
  },
];

export const DecisionPageEditor: React.FC<PageEditorProps<DecisionPage>> = ({ aid, page, onUpdate }) => {
  const extraTools = {
    replacement: ReplacementFactory(DecisionReplacements, aid),
    paragraph: {
      class: Paragraph,
      inlineToolbar: [...INLINE_TOOLS, "replacement"],
    },
  };

  return (
    <Container>
      <Tabs direction="column" width="200px">
        <TabItem title="Pre-amble">
          <Heading level={4}>Intro page</Heading>
          <Alert>This text will appear in the space before you ask the user for their decision.</Alert>
          <Alert variation="info">Double click the text to add a variable</Alert>
          <EditorBlock
            data={page.preamble}
            onChange={(newBlocks) => onUpdate({ preamble: newBlocks })}
            holder="feelings-preamble-container"
            extraTools={extraTools}
          />
        </TabItem>
        <TabItem title="Decision">
          <Heading level={4}>Decision</Heading>
          <Alert>
            The title will appear as a heading in a box that prompts users to make a decision. Add additional text below
            to provide more detail to users.
          </Alert>
          <TextField
            label="Title"
            value={page.decisionTitle}
            onChange={(e) => onUpdate({ decisionTitle: e.target.value })}
          />
          <Alert variation="info">Double click the text to add a variable</Alert>
          <EditorBlock
            data={page.decisionPrompt}
            onChange={(newBlocks) => onUpdate({ decisionPrompt: newBlocks })}
            holder="feelings-decision-prompt-container"
            extraTools={extraTools}
          />
        </TabItem>
        <TabItem title="Feelings">
          <Heading level={4}>Feelings</Heading>
          <Alert>The title will appear in a box that shows the ‘your feelings’ visualisation.</Alert>
          <TextField
            label="Title"
            value={page.feelingTitle}
            onChange={(e) => onUpdate({ feelingTitle: e.target.value })}
          />
        </TabItem>
        <TabItem title="Reflection">
          <Heading level={4}>Reflection</Heading>
          <Alert>
            The title will appear at the top of the box that helps the user reflect on their decision. Use the other
            tabs below to create different texts that appear according to the user’s alignment with their values.
          </Alert>
          <TextField
            label="Title"
            value={page.reflectionTitle}
            onChange={(e) => onUpdate({ reflectionTitle: e.target.value })}
          />
        </TabItem>
        {ReflectionBlocks.map(({ menu, title, data, info }) => (
          <IndentedTabItem title={menu} key={data as string}>
            <Heading level={4}>{title}</Heading>
            <Alert>{info}</Alert>
            <Alert variation="info">Double click the text to add a variable</Alert>
            <EditorBlock
              data={page[data] as OutputData}
              onChange={(newBlocks) => onUpdate({ [data]: newBlocks })}
              holder={`feelings-reflection-${data}-container`}
              extraTools={extraTools}
            />
          </IndentedTabItem>
        ))}
      </Tabs>
    </Container>
  );
};

const IndentedTabItem = styled(TabItem)`
  margin-left: 2em;
  position: relative;

  ::before {
    position: absolute;
    top: 0;
    z-index: -1;
    bottom: 0;
    left: -2em;
    width: 2em;
    content: "";
    background: var(--amplify-colors-neutral-10);
  }
`;

const Container = styled(View)`
  & > div {
    display: flex;
    gap: 1em;
  }

  & > div > [role="tablist"] {
    width: 200px;
  }

  & > div > [role="tablist"] .amplify-tabs-item[aria-selected="true"] {
    border-bottom-color: var(--amplify-components-tabs-item-border-color);
    border-right-width: var(--amplify-components-tabs-item-border-width);
  }

  & > div > :not([role="tablist"]) {
    flex-grow: 1;
    padding: 0.5em;
  }
`;
