import { useEffect, useState } from "react";
import { useUserGroups } from "./useUserGroups";
import { User, listAdmins, listEditors, listUsers } from "api/users";

interface Users {
  Admin: Array<User>;
  Editors: Array<User>;
  Other: Array<User>;
}

export const useUserList = () => {
  const [users, setUsers] = useState<Users>();
  const userGroups = useUserGroups();

  useEffect(() => {
    if (userGroups?.Admin && !users) {
      setUsers({ Admin: [], Editors: [], Other: [] });
      Promise.all([listUsers(), listEditors(), listAdmins()]).then(([users, editors, admins]) => {
        const adminIds = admins.map((u) => u.Username);
        const editorIds = editors.map((u) => u.Username);
        setUsers({
          Admin: admins,
          Editors: editors.filter((u) => !adminIds.includes(u.Username)),
          Other: users.filter((u) => ![...adminIds, ...editorIds].includes(u.Username)),
        });
      });
    }
  }, [userGroups, users]);

  return users;
};
