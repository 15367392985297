import { Auth } from "aws-amplify";
import { useState } from "react";
import { useEffectOnce } from "usehooks-ts";
import { ICredentials } from "@aws-amplify/core";

export const useCredentials = (): ICredentials | undefined => {
  const [credentials, setCredentials] = useState<ICredentials>();
  useEffectOnce(() => {
    Auth.currentUserCredentials().then((cred) => {
      setCredentials(cred);
    });
  });
  return credentials;
};
