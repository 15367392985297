import { RenderFn } from "editorjs-blocks-react-renderer";
import HTMLReactParser, { Element } from "html-react-parser";
import { ReplacementOption } from "./ReplacementFactory";
import { DecisionAid, DecisionAidState } from "../../../../types/DecisionAid";

export interface ParagraphBlockData {
  text: string;
}

function isText(data: any): data is string {
  return typeof data === "string";
}

export const ParagraphWithReplacement = (
  replacements: Array<ReplacementOption>,
  aid: DecisionAid,
  state?: DecisionAidState
): RenderFn<ParagraphBlockData> => {
  const Paragraph: RenderFn<ParagraphBlockData> = ({ data, className = "" }) => {
    const props: {
      [s: string]: string;
    } = {};

    if (className) {
      props.className = className;
    }

    const inner = data?.text ? (
      HTMLReactParser(data.text, {
        replace: (domNode) => {
          if (
            domNode instanceof Element &&
            domNode.type === "tag" &&
            domNode.name === "span" &&
            domNode.attribs["aria-replace"]
          ) {
            const replacement = replacements.find(({ id }) => id === domNode.attribs["aria-replace"]);
            if (replacement?.resolve) return replacement.resolve(aid, state);
            return <span>?</span>;
          }
        },
      })
    ) : (
      <></>
    );

    if (!isText(inner) && !Array.isArray(inner)) {
      return <>{inner}</>;
    }

    return <p {...props}>{inner}</p>;
  };
  return Paragraph;
};
