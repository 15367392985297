import "@aws-amplify/ui-react/styles.css";
import "react-tooltip/dist/react-tooltip.css";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/Home";
import { EditorRoutes } from "./pages/editor";
import { DecisionAid } from "./pages/aid";
import { HelmetProvider } from "react-helmet-async";

interface Props {}

export const App: React.FC<Props> = () => {
  return (
    <HelmetProvider>
      <Routes>
        <Route path="/editor/*" Component={EditorRoutes} />
        <Route path="/aid/:id/*" Component={DecisionAid} />
        <Route index Component={HomePage} />
      </Routes>
    </HelmetProvider>
  );
};
