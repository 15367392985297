/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CheckboxField, View } from "@aws-amplify/ui-react";
import { RenderFn } from "editorjs-blocks-react-renderer";
import HTMLReactParser from "html-react-parser";

export interface ChecklistBlockData {
  items: Array<{
    checked: boolean;
    text: string;
    change?: (val: boolean) => void;
  }>;
}

export const ChecklistRenderer: RenderFn<ChecklistBlockData> = ({ data: { items }, className = "" }) => {
  return (
    <View paddingLeft="1rem">
      {items.map((item, i) => (
        <CheckboxField
          key={i}
          css={css`
            break-inside: avoid;
            margin: 0.75rem 0;
            --amplify-components-checkbox-align-items: start;
            .amplify-checkbox__button {
              margin-top: 0.3em;
            }
          `}
          value={i.toString()}
          name={item.text}
          checked={item.checked}
          onChange={(e) => item.change?.(e.target.checked)}
          label={<div>{HTMLReactParser(item.text)}</div>}
        />
      ))}
    </View>
  );
};
