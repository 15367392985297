import { IconTableWithHeadings } from "@codexteam/icons";

export class TextBox {
  static get toolbox() {
    return {
      title: "Text Box",
      icon: IconTableWithHeadings,
    };
  }

  constructor({ api, data }) {
    this.api = api;
    this.data = data;
  }

  render() {
    const container = document.createElement("div");
    container.classList.add("block-textbox");
    container.classList.add("cdx-block");

    const placeholder = document.createElement("div");
    placeholder.classList.add("placeholder-input");
    placeholder.classList.add("cdx-input");
    placeholder.classList.add("cdx-input-placeholder");
    placeholder.setAttribute("contenteditable", "true");
    placeholder.dataset.placeholder = "A hint while the textbox is empty";
    placeholder.dataset.label = "Placeholder: ";
    if (this.data && this.data.placeholder) {
      placeholder.innerText = this.data.placeholder;
    }
    container.appendChild(placeholder);

    const defaultText = document.createElement("div");
    defaultText.classList.add("cdx-input");
    defaultText.classList.add("cdx-input-placeholder");
    defaultText.classList.add("default-input");
    defaultText.setAttribute("contenteditable", "true");
    defaultText.dataset.placeholder = "Text that starts in the textbox";
    defaultText.dataset.label = "Default Text: ";
    if (this.data && this.data.default) {
      defaultText.innerHTML = this.data.default;
    }
    container.appendChild(defaultText);

    return container;
  }

  save(container) {
    const placeholder = container.querySelector(".placeholder-input");
    const defaultText = container.querySelector(".default-input");
    return {
      placeholder: placeholder?.innerText || "",
      default: defaultText?.innerText || "",
    };
  }
}
