import { TextField, View } from "@aws-amplify/ui-react";
import { DecisionAidOption } from "../../types/DecisionAid";

interface Props {
  option: DecisionAidOption;
  index: number;
  onUpdate: (updates: Partial<DecisionAidOption>) => void;
}

export const OptionEditor: React.FC<Props> = ({ option, index, onUpdate }) => {
  return (
    <View>
      <TextField
        label={`Option ${index + 1} name`}
        value={option.name}
        onChange={(e) => onUpdate({ name: e.target.value })}
      />
    </View>
  );
};
