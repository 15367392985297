import { Alert, Heading, TextField, View } from "@aws-amplify/ui-react";
import { DecisionAid, DecisionAidOption } from "../../types/DecisionAid";
import { OptionEditor } from "./OptionEditor";

interface Props {
  aid: DecisionAid;
  onUpdate: (updates: Partial<Omit<DecisionAid, "id">>) => void;
}

export const GeneralSettingsEditor: React.FC<Props> = ({ aid, onUpdate }) => {
  const updateOption = (index: number, updates: Partial<DecisionAidOption>) => {
    onUpdate({
      options: aid.options.map((option, i) => (i === index ? { ...option, ...updates } : option)),
    });
  };

  return (
    <View>
      <Heading level={4}>Project name and title</Heading>
      <TextField
        label="Project name (not shown to users)"
        value={aid.name}
        onChange={(e) => onUpdate({ name: e.target.value })}
      />
      <TextField
        label="Decision aid title (shown to users)"
        value={aid.title}
        onChange={(e) => onUpdate({ title: e.target.value })}
      />
      <Heading level={4} marginTop="1rem">
        Options
      </Heading>
      <Alert>Identify the two options that users will learn about in this decision aid. </Alert>
      {aid.options.map((option, index) => (
        <OptionEditor key={index} option={option} index={index} onUpdate={updateOption.bind(null, index)} />
      ))}
    </View>
  );
};
