import { Button, TableCell, TableRow } from "@aws-amplify/ui-react";
import { User, moveToGroup } from "api/users";
import { useUserInfo } from "hooks/useUserInfo";
import { useNavigate } from "react-router-dom";

interface Props {
  user: User;
  type: "Admin" | "Editors" | "Other";
}

export const UserRow: React.FC<Props> = ({ user, type }) => {
  const info = useUserInfo();
  const navigate = useNavigate();

  const SetToEditor = async () => {
    await moveToGroup(user.Username, "Editors", type === "Other" ? undefined : type);
    navigate(0);
  };

  const SetToAdmin = async () => {
    await moveToGroup(user.Username, "Admin", type === "Other" ? undefined : type);
    navigate(0);
  };

  const SetToNone = async () => {
    await moveToGroup(user.Username, undefined, type === "Other" ? undefined : type);
    navigate(0);
  };

  return (
    <TableRow key={user.Username}>
      <TableCell>{user.Attributes.find((a) => a.Name === "email")?.Value}</TableCell>
      {info?.username !== user.Username && (
        <>
          {type !== "Editors" && (
            <TableCell>
              <Button onClick={SetToEditor}>Make Editor</Button>
            </TableCell>
          )}
          {type !== "Admin" && (
            <TableCell>
              <Button onClick={SetToAdmin}>Make Admin</Button>
            </TableCell>
          )}
          {type !== "Other" && (
            <TableCell>
              <Button onClick={SetToNone}>Remove Permissions</Button>
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  );
};
