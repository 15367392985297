import { Auth } from "aws-amplify";
import { useState } from "react";
import { useEffectOnce } from "usehooks-ts";

interface UserGroupState {
  Admin: boolean;
  Editor: boolean;
}

export const useUserGroups = (): UserGroupState | undefined => {
  const [userGroups, setUserGroups] = useState<UserGroupState>();

  useEffectOnce(() => {
    Auth.currentAuthenticatedUser().then((user: any) => {
      const groups: Array<string> = user.signInUserSession.accessToken.payload["cognito:groups"];
      setUserGroups({
        Admin: groups?.includes("Admin"),
        Editor: groups?.includes("Editors"),
      });
    });
  });

  return userGroups;
};
