import { ToggleButton } from "@aws-amplify/ui-react";
import styled from "@emotion/styled";

export const QuestionColours = ["red", "orange", "green", "teal", "blue", "purple", "pink", "yellow", "neutral"];

type Shade = "10" | "20" | "40" | "60" | "80" | "90" | "100";

export function colourFromName(name: string, shade: Shade) {
  return `var(--amplify-colors-${name}-${shade})`;
}

export function colourForIndex(index: number, shade: Shade) {
  return colourFromName(QuestionColours[index % QuestionColours.length], shade);
}

export const ColouredToggleButton = styled(ToggleButton)<{ pressedcolour: string }>`
  --hover: ${({ pressedcolour }) => colourFromName(pressedcolour, "40")};
  --press: ${({ pressedcolour }) => colourFromName(pressedcolour, "20")};
  --border: ${({ pressedcolour }) => colourFromName(pressedcolour, "40")};
  --text: ${({ pressedcolour }) => colourFromName(pressedcolour, "80")};

  --amplify-internal-button-menu-hover-background-color: var(--hover);
  --amplify-components-button-menu-focus-background-color: var(--hover);
  --amplify-components-button-menu-pressed-background-color: var(--press);
  --amplify-components-button-menu-pressed-focus-background-color: var(--press);
  --amplify-components-button-menu-pressed-hover-background-color: var(--press);
  --amplify-components-button-menu-active-background-color: var(--press);

  --amplify-internal-button-menu-hover-color: var(--text);
  --amplify-components-togglebutton-focus-color: var(--text);
  --amplify-components-togglebutton-pressed-color: var(--text);
  --amplify-components-togglebutton-pressed-focus-color: var(--text);
  --amplify-components-togglebutton-pressed-hover-color: var(--text);

  --amplify-components-button-menu-pressed-border-color: var(--border);
  --amplify-components-button-menu-pressed-focus-border-color: var(--border);
  --amplify-components-button-menu-pressed-hover-border-color: var(--border);
  --amplify-components-togglebutton-pressed-border-color: var(--border);
  --amplify-components-togglebutton-pressed-focus-border-color: var(--border);
  --amplify-components-togglebutton-pressed-hover-border-color: var(--border);

  --amplify-components-button-menu-pressed-box-shadow: var(--border) 0px 0px 1px 1px;
  --amplify-components-button-menu-focus-box-shadow: var(--border) 0px 0px 1px 1px;
  --amplify-components-button-menu-pressed-focus-box-shadow: var(--border) 0px 0px 1px 1px;
  --amplify-components-button-menu-pressed-hover-box-shadow: var(--border) 0px 0px 1px 1px;

  --amplify-components-togglebutton-hover-background-color: var(--hover);
  --amplify-components-togglebutton-focus-background-color: var(--hover);
  --amplify-components-togglebutton-active-background-color: var(--press);
  --amplify-components-togglebutton-pressed-background-color: var(--press);
  --amplify-components-button-menu-focus-background-color: var(--press);
  --amplify-components-togglebutton-pressed-hover-background-color: var(--press);
`;
