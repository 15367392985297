import { Button, Heading, View } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

interface Props {}

export const HomePage: React.FC<Props> = () => {
  const navigate = useNavigate();
  return (
    <View>
      <Heading level={1}>Decision Aids</Heading>
      <Button variation="link" onClick={() => navigate("/editor")}>
        Dashboard
      </Button>
    </View>
  );
};
