import { useMemo } from "react";
import { DecisionAidState } from "../types/DecisionAid";
import { Question } from "../types/Question";

export const useAllQuestions = (questionList: Array<Question>, state?: DecisionAidState): Array<Question> => {
  const combinedQuestions = useMemo(() => {
    const questions = [
      ...questionList.filter((q) => !state?.deletedQuestions.includes(q.id)),
      ...(state?.extraQuestions || []),
    ];

    if (!state?.questionOverrides) return questions;
    const overriddenKeys = Object.keys(state.questionOverrides);
    if (overriddenKeys.length === 0) return questions;

    return overriddenKeys.reduce<Array<Question>>((curr, qid) => {
      const index = curr.findIndex(({ id }) => id === qid);
      const { sides, ...otherOverrides } = state.questionOverrides[qid];
      if (index >= 0) {
        curr[index] = {
          ...curr[index],
          ...otherOverrides,
          sides: curr[index].sides.map((s, index) => {
            if (!sides || !sides[index]) return s;
            return { ...s, ...sides[index] };
          }),
        };
      }
      return curr;
    }, questions);
  }, [state?.questionOverrides, state?.extraQuestions, state?.deletedQuestions, questionList]);
  return combinedQuestions;
};
