import { IconBrackets } from "@codexteam/icons";

export class HTMLEditor {
  constructor({ api }) {
    this.api = api;
    this.button = null;
    this.editor = null;
    this.pre = null;
    this.saveButton = null;
    this.expandButton = null;
    this.el = null;
    this.isBlockNode = false;
    // this.styleEditor = null;
  }

  static get sanitize() {
    return {
      div: true,
      table: true,
      th: true,
      tr: true,
      td: true,
      li: true,
      ul: true,
      ol: true,
      img: true,
      br: true,
    };
  }

  static get isInline() {
    return true;
  }

  clear() {
    this.hideActions();
  }

  get state() {
    return this._state;
  }

  set state(state) {
    this._state = state;

    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
  }

  renderActions() {
    this.editor = document.createElement("div");

    this.pre = document.createElement("pre");
    this.pre.setAttribute("contenteditable", true);
    this.pre.classList.add("html-editor");
    this.pre.addEventListener("paste", (event) => {
      event.stopPropagation();
      // cancel paste
      event.preventDefault();

      // get text representation of clipboard
      var text = (event.originalEvent || event).clipboardData.getData("text/plain");

      // insert text manually
      document.execCommand("insertText", false, text);
    });

    // this.styleEditor = document.createElement("pre");
    // this.styleEditor.setAttribute("contenteditable", "true");
    // this.styleEditor.classList.add("style-editor");

    this.expandButton = document.createElement("button");
    this.expandButton.innerText = "Expand Selection";
    this.expandButton.classList.add("html-editor-button");

    this.saveButton = document.createElement("button");
    this.saveButton.innerText = "Save";
    this.saveButton.classList.add("html-editor-button");

    const buttonParent = document.createElement("div");
    buttonParent.classList.add("html-editor-buttons");
    buttonParent.appendChild(this.expandButton);
    buttonParent.appendChild(this.saveButton);

    const instructions = document.createElement("div");
    instructions.innerHTML = `Use <pre>&lt;div class="expand-to-outer"&gt;</pre> or <pre>&lt;span class="expand-to-outer"&gt;</pre> to apply a style to a paragraph or table cell`;
    instructions.classList.add("html-editor-instructions");

    // this.editor.appendChild(this.styleEditor);
    this.editor.appendChild(this.pre);
    this.editor.appendChild(buttonParent);
    this.editor.appendChild(instructions);

    return this.editor;
  }

  render() {
    this.button = document.createElement("button");
    this.button.type = "button";
    this.button.innerHTML = IconBrackets;
    this.button.classList.add(this.api.styles.inlineToolButton);

    return this.button;
  }

  setBlockContent() {
    this.pre.textContent = this.el.innerHTML;
    this.expandButton.toggleAttribute("disabled", this.isBlockNode);
    // this.styleEditor.hidden = !this.isBlockNode;
    // if (this.isBlockNode) {
    //   this.styleEditor.innerText = this.el.getAttribute("style");
    // }
  }

  showActions() {
    this.editor.hidden = false;
    this.setBlockContent();
    this.saveButton.onclick = () => {
      // if (this.isBlockNode) {
      this.el.innerHTML = this.pre.textContent;
      // this.el.setAttribute("style", this.styleEditor.innerText);
      // } else {
      //   this.el.outerHTML = this.pre.textContent;
      // }
    };
    this.expandButton.onclick = () => {
      if (!this.isBlockNode) {
        this.el = this.el.parentNode;
        this.isBlockNode = this.el.getAttribute("contenteditable") === "true";
        this.setBlockContent();
      }
    };
  }

  hideActions() {
    this.editor.hidden = true;
    this.pre.textContent = "";
    this.saveButton.onclick = null;
    this.expandButton.onclick = null;
  }

  surround(range) {
    if (this.state) {
      this.unwrap(range);
      return;
    }

    this.wrap(range);
  }

  wrap(range) {
    this.state = true;
    let ele = range.commonAncestorContainer;
    if (ele.nodeType === Node.TEXT_NODE) {
      ele = ele.parentNode;
    }
    this.isBlockNode = ele.getAttribute("contenteditable") === "true";
    this.el = ele;
  }

  unwrap(range) {
    this.state = false;
    this.el = null;
  }

  checkState(selection) {
    if (this.state) {
      if (this.el) this.showActions();
    } else {
      this.hideActions();
    }
  }
}
