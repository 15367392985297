import { API, Auth } from "aws-amplify";

const apiName = "AdminQueries";

export interface User {
  Username: string;
  Attributes: Array<{
    Name: "sub" | "email" | "email_verified";
    Value: string;
  }>;
}

export type Group = "Admin" | "Editors";

async function GetHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
  };
}

export const listUsers = async (): Promise<Array<User>> => {
  let path = "/listUsers";
  let myInit = {
    headers: await GetHeaders(),
  };
  const { Users } = await API.get(apiName, path, myInit);
  return Users;
};

export const listUsersInGroup = async (groupName: string): Promise<Array<User>> => {
  let path = "/listUsersInGroup";
  let myInit = {
    queryStringParameters: {
      groupname: groupName,
    },
    headers: await GetHeaders(),
  };
  const { Users } = await API.get(apiName, path, myInit);
  return Users;
};

export const listEditors = async (): Promise<Array<User>> => {
  return await listUsersInGroup("Editors");
};

export const listAdmins = async (): Promise<Array<User>> => {
  return await listUsersInGroup("Admin");
};

export const moveToGroup = async (username: string, newGroup?: Group, existingGroup?: Group) => {
  if (existingGroup) {
    let apiName = "AdminQueries";
    let path = "/removeUserFromGroup";
    let myInit = {
      body: {
        username: username,
        groupname: existingGroup,
      },
      headers: await GetHeaders(),
    };
    await API.post(apiName, path, myInit);
  }

  if (newGroup) {
    let apiName = "AdminQueries";
    let path = "/addUserToGroup";
    let myInit = {
      body: {
        username: username,
        groupname: newGroup,
      },
      headers: await GetHeaders(),
    };
    await API.post(apiName, path, myInit);
  }
};
