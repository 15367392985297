import { memo, useCallback, useEffect, useRef, useState } from "react";
import EditorJS, { API, OutputData } from "@editorjs/editorjs";
import { EDITOR_TOOLS } from "./EditorTools";
import { useEffectOnce } from "usehooks-ts";
import styled from "@emotion/styled";

//props
type Props = {
  id?: string;
  data?: OutputData;
  onChange(val: OutputData): void;
  holder: string;
  extraTools?: object;
};

const EditorBlockComponent = ({ id, data, onChange, holder, extraTools }: Props) => {
  //add a reference to editor
  const ref = useRef<EditorJS>();
  const [loadedId, setLoadedId] = useState<string>();

  const runChange = useCallback(
    async (api: API) => {
      const data = await api.saver.save();
      onChange(data);
    },
    [onChange]
  );

  //initialize editorjs
  useEffectOnce(() => {
    //initialize editor if we don't have a reference
    if (!ref.current) {
      const editor = new EditorJS({
        holder: holder,
        tools: { ...EDITOR_TOOLS, ...extraTools },
        data,
        onChange: runChange,
      });
      ref.current = editor;
      setLoadedId(id);
    }

    //add a return function handle cleanup
    return () => {
      if (ref.current && ref.current.destroy) {
        ref.current.destroy();
      }
    };
  });

  useEffect(() => {
    if (id !== loadedId && loadedId !== undefined) {
      if (ref.current && ref.current.destroy) {
        ref.current.destroy();
      }

      const editor = new EditorJS({
        holder: holder,
        tools: { ...EDITOR_TOOLS, ...extraTools },
        data,
        onChange: runChange,
      });
      ref.current = editor;
      setLoadedId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, loadedId, ref]);

  return <Holder id={holder} />;
};

export const EditorBlock = memo(EditorBlockComponent);

const Holder = styled.div`
  .codex-editor__redactor {
    padding-bottom: 3em !important;
  }
`;
