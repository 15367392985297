import { Button, Flex } from "@aws-amplify/ui-react";
import { ReactNode, useEffect, useRef, useState } from "react";

interface Props {
  children?: ReactNode;
  confirmText: string;
  cancelText: string;
  isOpen: boolean;
  close: () => void;
  confirm: () => void;
}

const ConfirmModal: React.FC<Props> = ({ confirm, close, isOpen, children, confirmText, cancelText }) => {
  const dialogRef = useRef<null | HTMLDialogElement>(null);

  useEffect(() => {
    if (dialogRef.current) {
      if (isOpen && !dialogRef.current.open) {
        dialogRef.current.showModal();
      } else if (!isOpen && dialogRef.current.open) {
        dialogRef.current.close();
      }
    }
  }, [isOpen]);

  return (
    <dialog ref={dialogRef}>
      {children}
      <Flex justifyContent="center" gap="2em" marginTop="1em">
        <Button onClick={close}>{cancelText}</Button>
        <Button variation="destructive" onClick={confirm}>
          {confirmText}
        </Button>
      </Flex>
    </dialog>
  );
};

export function useConfirmModal<TState>(
  onConfirm: (state: TState) => void,
  confirmText: string = "Confirm",
  onClose?: () => void,
  cancelText: string = "Cancel"
): [ReactNode, (c: ReactNode, s: TState) => void, () => void, TState | undefined] {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [state, setState] = useState<TState>();
  const [children, setChildren] = useState<ReactNode>();

  const close = () => {
    setIsOpen(false);
    setState(undefined);
    onClose && onClose();
  };

  const confirm = () => {
    if (!state) return;
    onConfirm(state);
    close();
  };

  const open = (children: ReactNode, s: TState) => {
    setChildren(children);
    setState(s);
    setIsOpen(true);
  };

  const Modal = (
    <ConfirmModal confirmText={confirmText} cancelText={cancelText} confirm={confirm} close={close} isOpen={isOpen}>
      {children}
    </ConfirmModal>
  );

  return [Modal, open, close, state];
}
