/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Flex, Grid, Text, View, useTheme } from "@aws-amplify/ui-react";
import { DecisionAid, DecisionAidState } from "../types/DecisionAid";
import { Question } from "../types/Question";
import styled from "@emotion/styled";
import { ColouredToggleButton, QuestionColours, colourForIndex } from "./ColouredButtons";
import { Fragment } from "react";
import { OptionForValue } from "./editor/tools/Replacements/DecisionReplacements";
import { Tooltip } from "react-tooltip";

interface Props {
  values: DecisionAidState["questionValues"];
  questions: Array<Question>;
  aid: DecisionAid;
  selectedIndex?: number;
  selectQuestion?: (index: number) => void;
  state?: DecisionAidState;
}

export const ReasonsGraph: React.FC<Props> = ({ state, selectedIndex, selectQuestion, values, questions, aid }) => {
  const theme = useTheme();
  const sum = questions.reduce((c, q) => c + values[q.id], 0);
  const decisionValue = OptionForValue(sum);

  const questionsWithIndices = questions.map((q, i) => ({ ...q, index: i }));

  questionsWithIndices.sort((a, b) => {
    const valOf = (q: Question) => {
      const val = state?.questionValues[q.id];
      if (!val) return 0;

      if ((decisionValue === 0 && val < 0) || (decisionValue === 1 && val > 0)) {
        return 100 + Math.abs(val);
      }
      return Math.abs(val);
    };
    return valOf(b) - valOf(a);
  });

  const questionsWithOrder = questionsWithIndices.map((q, i) => ({ ...q, order: i }));

  questionsWithOrder.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });

  return (
    <Graph>
      <OuterGrid margin="0" marginBottom="0.5em" autoFlow="row" position="relative">
        {questionsWithOrder.map((question) => (
          <Fragment key={question.id}>
            <Tooltip style={{ zIndex: 1000 }} id={`graph-tooltip-${question.id}`} />
            <View
              backgroundColor={theme.tokens.colors.neutral[10]}
              css={css`
                width: 100%;
                height: 100%;
                grid-area: ${question.order + 1} / 1;
              `}
            />
            <InnerGrid
              css={css`
                position: absolute;
                top: calc(${question.order} * (var(--row-height) + var(--row-gap)));
                width: 100%;
                height: var(--row-height);
              `}
              margin="0"
              templateColumns="1fr clamp(6em, 15%, 15em) 1fr"
            >
              <View
                css={css`
                  width: 100%;
                  height: 100%;
                  grid-area: 1 / 1;
                  z-index: 10;
                  background: transparent;
                `}
                data-tooltip-id={`graph-tooltip-${question.id}`}
                data-tooltip-content={question.sides[0].title}
              />
              <View
                css={css`
                  width: 100%;
                  height: 100%;
                  grid-area: 1 / 3;
                  z-index: 10;
                  background: transparent;
                `}
                data-tooltip-id={`graph-tooltip-${question.id}`}
                data-tooltip-content={question.sides[1].title}
              />
              <View
                backgroundColor={
                  OptionForValue(values[question.id]) === "?"
                    ? theme.tokens.colors.neutral[20]
                    : colourForIndex(question.index, "10")
                }
                css={css`
                  width: 100%;
                  height: 100%;
                  grid-area: 1 / 2;
                `}
              />
              <GraphPiece
                css={css`
                  grid-area: 1 / 3;
                  justify-self: start;
                `}
                backgroundColor={colourForIndex(question.index, decisionValue === 1 ? "40" : "20")}
                width={`${(100 * Math.max(0, values[question.id])) / question.sides[1].value}%`}
              />
              <GraphPieceButton
                disabled={!selectQuestion}
                css={css`
                  cursor: default;
                  grid-area: 1 / 2;
                  ${Math.abs(values[question.id]) > 0 && `color: ${colourForIndex(question.index, "90")};`}
                  ${!selectQuestion && `color: var(--amplify-colors-neutral-100) !important;`}
                `}
                pressedcolour={QuestionColours[question.index % QuestionColours.length]}
                onClick={() => selectQuestion && selectQuestion(question.index)}
                variation="menu"
                isPressed={selectedIndex === question.index}
              >
                {question.label}
              </GraphPieceButton>
              <GraphPiece
                css={css`
                  grid-area: 1 / 1;
                  justify-self: end;
                `}
                backgroundColor={colourForIndex(question.index, decisionValue === 0 ? "40" : "20")}
                width={`${(100 * Math.max(0, -values[question.id])) / -question.sides[0].value}%`}
              />
            </InnerGrid>
          </Fragment>
        ))}

        <Grid
          css={css`
            grid-area: ${questions.length + 1} / 1;
            line-height: 1;
            font-size: clamp(0.8em, 1.8dvh, 1em);
          `}
          margin="0"
          templateColumns="1fr clamp(6em, 15%, 15em) 1fr"
        >
          <ReasonText
            color={theme.tokens.colors.neutral[OptionForValue(sum) === 0 ? 100 : 60]}
            textAlign="right"
            css={css`
              grid-area: 1 / 1;
            `}
          >
            Reasons to <br />
            <strong>{aid.options[0].name}</strong>
          </ReasonText>

          <ReasonText
            css={css`
              grid-area: 1 / 3;
            `}
            color={theme.tokens.colors.neutral[OptionForValue(sum) === 1 ? 100 : 60]}
            textAlign="left"
          >
            Reasons to <br />
            <strong>{aid.options[1].name}</strong>
          </ReasonText>
        </Grid>
      </OuterGrid>
    </Graph>
  );
};

const InnerGrid = styled(Grid)`
  transition: top 300ms ease-in-out;
`;

const OuterGrid = styled(Grid)`
  --row-height: clamp(1.7em, 4.5vh, 10em);
  --row-height: clamp(1.7em, 4.5dvh, 10em);
  --row-gap: 0.1em;
  grid-auto-rows: var(--row-height);
  grid-row-gap: var(--row-gap);
`;

const ReasonText = styled(Text)`
  font-size: 0.8em;

  @media (min-width: 500px) {
    font-size: 1em;
  }

  @media (min-width: 1000px) {
    font-size: 1.2em;
  }

  margin-bottom: 0.5em;
`;

const Graph = styled(View)`
  --min-piece-width: 5em;
`;

const GraphPieceButton = styled(ColouredToggleButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: clamp(0.5rem, 2vw, 0.8em);
  height: var(--row-height);
  border-radius: 0;
  padding: 0.25em;
  line-height: 1rem;
  overflow: hidden;

  &:focus {
    border-width: 1px;
    border-style: solid;
  }

  &.amplify-togglebutton--pressed {
    border-width: 1px;
    border-style: solid;
  }

  transition: grid-area 500ms ease-in-out;
`;

const GraphPiece = styled(Flex)`
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: rows;
  height: 100%;
  flex-basis: var(--min-piece-width);
  flex-grow: 1;
  padding: 0.25em 0;
`;
