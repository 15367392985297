import { API, Auth } from "aws-amplify";
import { DecisionAid, DecisionAidOption } from "../types/DecisionAid";
import { v4 as uuidv4 } from "uuid";

const API_NAME = "decisionAidApi";
const BASE_PATH = "/decisionAids";

function processAidList(aids: Array<Partial<DecisionAid>>): Array<DecisionAid> {
  return aids.map(processDecisionAid);
}

function MakeDefaultOption(): DecisionAidOption {
  return { name: "" };
}

export function processDecisionAid(aid: Partial<DecisionAid>): DecisionAid {
  return {
    id: aid.id || uuidv4(),
    userId: aid.userId,
    name: aid.name || "",
    title: aid.title || aid.name || "",
    pages: aid.pages || [
      {
        id: uuidv4(),
        name: "Your Feelings",
        type: "feelings",
        questions: [],
      },
      {
        id: uuidv4(),
        name: "Your Decision",
        type: "decision",
        decisionTitle: "Your Decision",
        feelingTitle: "Your feelings",
        reflectionTitle: "How does your decision fit with what you said matters most?",
      },
    ],
    options: aid.options || [MakeDefaultOption(), MakeDefaultOption()],
    defaultNewQuestion: aid.defaultNewQuestion || {
      type: "slider",
      label: `My Value`,
      zeroLabel: "Equally Important",
      isExtra: true,
      sides: [
        {
          value: -100,
          title: `Reason to`,
          label: "Most Important",
        },
        {
          value: 100,
          title: `Reason to`,
          label: "Most Important",
        },
      ],
    },
  };
}

export const loadDecisionAids = async (): Promise<Array<DecisionAid>> => {
  const resp: { data: Array<Partial<DecisionAid>> } = await API.get(API_NAME, BASE_PATH, {});
  return processAidList(resp.data);
};

export const loadDecisionAid = async (id: string): Promise<DecisionAid> => {
  const resp: Partial<DecisionAid>[] = await API.get(API_NAME, `${BASE_PATH}/${id}`, {});
  if (resp.length === 0 || resp[0].id !== id) {
    throw new Error("Could not find decision aid");
  }
  return processDecisionAid(resp[0]);
};

export const deleteDecisionAid = async (id: string): Promise<void> => {
  await API.del(API_NAME, `${BASE_PATH}/object/${id}`, {});
};

export const setDecisionAid = async (aid: DecisionAid): Promise<void> => {
  const creds = await Auth.currentUserCredentials();
  //Don't save a decision aid you don't own
  if (aid.userId && aid.userId !== creds.identityId) {
    return;
  }
  await API.put(API_NAME, `${BASE_PATH}`, {
    body: aid,
  });
};
