import { TextAreaField, View } from "@aws-amplify/ui-react";
import { RenderFn } from "editorjs-blocks-react-renderer";

export interface TextBoxBlockData {
  placeholder: string;
  default: string;
  change?: (val: string) => void;
}

export const TextBoxRenderer: RenderFn<TextBoxBlockData> = ({ data: { placeholder, default: text, change } }) => {
  return (
    <View padding="0.5rem">
      <TextAreaField
        label=""
        value={text}
        onChange={(e) => change?.(e.target.value)}
        labelHidden={true}
        placeholder={placeholder}
      />
    </View>
  );
};
