import { KnowledgePage } from "../../../types/Page";
import { PageRendererProps } from ".";
import { View } from "@aws-amplify/ui-react";
import { BlockRenderer } from "components/editor/tools/BlockRenderer";
import { PageNav } from "./PageNav";
import { BlockStates } from "types/DecisionAid";

export const KnowledgePageRenderer: React.FC<PageRendererProps<KnowledgePage>> = ({
  aid,
  page,
  state,
  updateState,
  pageIndex,
  prevPage,
  nextPage,
}) => {
  const updateBlockState = (changes: Partial<BlockStates>): void => {
    updateState?.({
      blockStates: {
        checklist: {},
        textbox: {},
        ...state?.blockStates,
        ...changes,
      },
    });
  };

  return (
    <View>
      <BlockRenderer data={page.blocks} state={state?.blockStates} setState={updateBlockState} />
      <PageNav
        nextTitle={Boolean(nextPage) ? aid.pages[pageIndex + 1].name : undefined}
        prevTitle={Boolean(prevPage) ? aid.pages[pageIndex - 1].name : undefined}
        canGoNext={Boolean(nextPage)}
        nextPage={nextPage}
        canGoPrev={Boolean(prevPage)}
        prevPage={prevPage}
      />
    </View>
  );
};
