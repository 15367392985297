import { ButtonGroup, Icon } from "@aws-amplify/ui-react";
import styled from "@emotion/styled";
import { ColouredToggleButton, QuestionColours, colourForIndex } from "components/ColouredButtons";
import { HiEllipsisHorizontalCircle } from "react-icons/hi2";
import { MdCircle, MdInfo } from "react-icons/md";
import { Question } from "types/Question";
import { PageNav } from "../PageNav";

interface Props {
  selected: number;
  setSelected: (index: number) => void;
  questions: Array<Question>;
  nextPage?: () => void;
  prevPage?: () => void;
}

export const FeelingsPageNav: React.FC<Props> = ({ nextPage, prevPage, selected, setSelected, questions }) => {
  return (
    <PageNav
      canGoNext={selected < questions.length || Boolean(nextPage)}
      canGoPrev={selected >= 0 || Boolean(prevPage)}
      nextPage={() => (selected >= questions.length ? nextPage && nextPage() : setSelected(selected + 1))}
      prevPage={() => (selected < 0 ? prevPage && prevPage() : setSelected(selected - 1))}
    >
      <ButtonGroup height="100%" alignItems="center" variation="menu" gap="0.25em">
        <NavToggleButton
          isPressed={selected === -1}
          pressedcolour={"neutral"}
          variation="menu"
          onClick={() => setSelected(-1)}
        >
          <Icon as={MdInfo} />
        </NavToggleButton>
        {questions.map((q, i) => (
          <NavToggleButton
            variation="primary"
            pressedcolour={QuestionColours[i % QuestionColours.length]}
            isPressed={selected === i}
            key={q.id}
            onClick={() => setSelected(i)}
          >
            <Icon as={MdCircle} color={colourForIndex(i, "40")} />
          </NavToggleButton>
        ))}
        <NavToggleButton
          isPressed={selected === questions.length}
          pressedcolour={"neutral"}
          variation="menu"
          onClick={() => setSelected(questions.length)}
        >
          <Icon as={HiEllipsisHorizontalCircle} />
        </NavToggleButton>
      </ButtonGroup>
    </PageNav>
  );
};

const NavToggleButton = styled(ColouredToggleButton)`
  padding: 0.25em;
  margin: 0;
  @media (max-width: 500px) {
    font-size: 0.75em;
  }
`;
