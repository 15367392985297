import {
  Button,
  Flex,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  WithAuthenticatorProps,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { BsPersonCircle, BsQuestionCircleFill } from "react-icons/bs";
import { Routes, Route } from "react-router-dom";
import { EditorDashboard } from "./Dashboard";
import { AidEditor } from "./AidEditor";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";

interface Props extends WithAuthenticatorProps {}

const Editor: React.FC<Props> = ({ signOut, user }) => {
  const [email, setEmail] = useState<string>();

  useEffect(() => {
    if (user && email === undefined) {
      user.getUserAttributes((_, attrs) => {
        const emailAttr = attrs?.find((attr) => attr.Name === "email");
        if (emailAttr) setEmail(emailAttr.Value);
      });
    }
  }, [user, email]);

  return (
    <Container>
      <Flex justifyContent="space-between" style={{ margin: "0.5em 2rem" }}>
        <Heading level={1}>Decision Aid Editor</Heading>
        <Flex>
          <Button
            variation="menu"
            size="large"
            onClick={() => window.open("https://www.sydneyhealthliteracylab.org.au/da-help", "_blank")}
          >
            <Icon as={BsQuestionCircleFill} />
          </Button>
          <Menu
            menuAlign="start"
            trigger={
              <MenuButton variation="menu" size="large">
                <Icon as={BsPersonCircle} />
              </MenuButton>
            }
          >
            <MenuItem isDisabled>Signed in as {email || ""}</MenuItem>
            <MenuItem onClick={signOut}>Sign Out</MenuItem>
          </Menu>
        </Flex>
      </Flex>

      <Routes>
        <Route path="aid/:id/*" Component={AidEditor} />
        <Route index Component={EditorDashboard} />
      </Routes>
    </Container>
  );
};

const Container = styled(Flex)`
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
`;

export const EditorRoutes = withAuthenticator(Editor);
