import { useState } from "react";
import { useEffect } from "react";

export function useDebouncedEffect(
  func: React.EffectCallback,
  deps: React.DependencyList,
  delay?: number,
  dest?: ReturnType<React.EffectCallback>
): void {
  const [destructor, setDestructor] = useState<ReturnType<React.EffectCallback>>(dest);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDestructor(func());
    }, delay || 500);

    return () => {
      clearTimeout(timer);
      if (destructor) destructor();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, delay]);
}
