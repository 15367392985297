const Tag = "SPAN";
const Class = "replacement";
const ReplaceAttribute = "aria-replace";
const NullReplaceId = "?";

export function ReplacementTool(options) {
  return class Replacement {
    constructor({ api }) {
      this.api = api;
      this.button = null;
      this.dropdown = null;

      this.tag = Tag;
      this.class = Class;
    }

    static get sanitize() {
      return {
        span: {
          class: Class,
          [ReplaceAttribute]: true,
          contenteditable: true,
        },
      };
    }

    static get isInline() {
      return true;
    }

    clear() {
      this.hideActions();
    }

    get state() {
      return this._state;
    }

    set state(state) {
      this._state = state;

      this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
    }

    renderActions() {
      this.dropdown = document.createElement("select");

      const nullOption = document.createElement("option");
      nullOption.value = NullReplaceId;
      nullOption.textContent = "<Choose a Variable>";
      nullOption.disabled = true;
      this.dropdown.appendChild(nullOption);

      options.forEach(({ id, label, text }) => {
        const option = document.createElement("option");
        option.value = id;
        option.textContent = text ? `${label}: ${text}` : label;
        this.dropdown.appendChild(option);
      });
      this.dropdown.value = NullReplaceId;
      this.dropdown.hidden = true;

      return this.dropdown;
    }

    render() {
      this.button = document.createElement("button");
      this.button.type = "button";
      this.button.textContent = "Add Variable";
      this.button.classList.add(this.api.styles.inlineToolButton);

      return this.button;
    }

    showActions(span) {
      this.dropdown.onchange = () => {};
      this.dropdown.hidden = false;
      this.dropdown.value = span.getAttribute(ReplaceAttribute);

      this.dropdown.onchange = () => {
        span.setAttribute(ReplaceAttribute, this.dropdown.value);
        const option = options.find(({ id }) => id === this.dropdown.value);
        span.textContent = option ? (option.text ? `${option.label}: ${option.text}` : option.label) : "?";
      };
    }

    hideActions() {
      this.dropdown.onchange = null;
      this.dropdown.hidden = true;
      this.dropdown.onchange = null;
    }

    surround(range) {
      if (this.state) {
        this.unwrap(range);
        return;
      }

      this.wrap(range);
    }

    wrap(range) {
      const span = document.createElement(this.tag);
      span.classList.add(this.class);
      span.setAttribute(ReplaceAttribute, NullReplaceId);
      span.textContent = "?";
      span.setAttribute("contenteditable", "false");
      range.insertNode(span);

      this.api.selection.expandToTag(span);
    }

    unwrap(range) {
      const span = this.api.selection.findParentTag(this.tag, this.class);

      span.remove();
    }

    checkState() {
      const span = this.api.selection.findParentTag(this.tag, this.class);
      this.state = !!span;

      if (this.state) {
        this.api.selection.expandToTag(span);
        this.showActions(span);
      } else {
        this.hideActions();
      }
    }
  };
}
