import { FormEvent, useEffect, useState } from "react";
import { useDecisionAids } from "../../hooks/useDecisionAids";
import { useNavigate } from "react-router-dom";
import { deleteDecisionAid, processDecisionAid, setDecisionAid } from "../../api/decisionAids";
import { DecisionAid } from "../../types/DecisionAid";
import {
  Alert,
  Button,
  Heading,
  Icon,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import styled from "@emotion/styled";
import { MdEdit, MdOpenInNew, MdOutlineAddCircleOutline } from "react-icons/md";
import { useConfirmModal } from "../../components/ConfirmModal";
import { useCredentials } from "hooks/useCredentials";
import { useUserList } from "hooks/useUserList";
import { UsersTableBlock } from "components/users/UsersTableBlock";
import { useUserGroups } from "hooks/useUserGroups";
import { Helmet } from "react-helmet-async";
import { v4 as uuidv4 } from "uuid";

interface Props {}

export const EditorDashboard: React.FC<Props> = () => {
  const [loadedAids] = useDecisionAids();
  const [aids, setAids] = useState<DecisionAid[]>([]);
  const [name, setName] = useState<string>("");
  const navigate = useNavigate();
  const credentials = useCredentials();
  const userList = useUserList();
  const userGroups = useUserGroups();
  const [isImporting, setIsImporting] = useState<boolean>();

  async function deleteAid(id: string) {
    setAids(aids?.filter((a) => a.id !== id));
    await deleteDecisionAid(id);
  }

  const [DeleteModal, openDeleteModal] = useConfirmModal<DecisionAid>((aid) => aid && deleteAid(aid.id), "Delete");

  useEffect(() => {
    if (aids.length === 0 && loadedAids && loadedAids.length > 0) {
      setAids(loadedAids);
    }
  }, [loadedAids, aids.length]);

  async function createNewAid(e: FormEvent) {
    e.preventDefault();
    const newDecisionAid = processDecisionAid({
      name,
    });
    await setDecisionAid(newDecisionAid);
    navigate(`aid/${newDecisionAid.id}`);
  }

  const myAids = aids?.filter((aid) => aid.userId && aid.userId === credentials?.identityId);

  function importFile(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target?.result;
      if (typeof text === "string" || text instanceof String) {
        setIsImporting(true);
        const aid: DecisionAid = {
          id: uuidv4(),
          ...JSON.parse(text as string),
        };
        await setDecisionAid(aid);
        setIsImporting(false);
        navigate(`aid/${aid.id}`);
      }
    };
    if (e.target?.files?.[0] && e.target?.files?.[0].type === "application/json") {
      reader.readAsText(e.target.files[0]);
    }
  }

  return (
    <Container>
      <Helmet>
        <title>Decision Aids - Dashboard</title>
      </Helmet>
      {DeleteModal}
      {loadedAids && userGroups ? (
        <>
          {!userGroups.Admin && !userGroups.Editor ? (
            <Alert variation="info" heading="You don't have permissions to create or edit Decision Aids">
              <p>To gain access:</p>
              <ul>
                <li>Ask an administrator to give you access</li>
                <li>Sign out and sign back in to confirm that you have access</li>
              </ul>
            </Alert>
          ) : (
            <>
              <Heading level={2}>My Aids</Heading>
              <Table variation="bordered">
                <TableBody>
                  {myAids.map((aid) => (
                    <TableRow key={aid.id}>
                      <TableCell>{aid.name}</TableCell>
                      <TableCell>
                        <Button onClick={() => window.open(`/aid/${aid.id}`, "_blank")}>
                          <Icon as={MdOpenInNew} marginRight={"0.5em"} />
                          View
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => navigate(`aid/${aid.id}`)}>
                          <Icon as={MdEdit} marginRight={"0.5em"} />
                          Edit
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          variation="destructive"
                          onClick={() => openDeleteModal(<Text>Are you sure you want to delete {aid.name}?</Text>, aid)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <View>
                <TextField
                  label="Add New Decision Aid"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  outerEndComponent={
                    <Button variation="primary" isDisabled={isImporting || name.length === 0} onClick={createNewAid}>
                      <Icon as={MdOutlineAddCircleOutline} />
                    </Button>
                  }
                />
              </View>

              <View style={{ marginTop: "1rem" }}>
                <label className="amplify-label">Import existing Decision Aid</label>
                <input
                  style={{ display: "block" }}
                  type="file"
                  disabled={isImporting}
                  onChange={(e) => importFile(e)}
                />
              </View>
            </>
          )}
        </>
      ) : (
        <Loader size="large" />
      )}

      {userList && userList.Admin.length > 0 && (
        <>
          <Heading level={2} marginTop="1rem">
            User Admin
          </Heading>
          <Table>
            <UsersTableBlock users={userList.Admin} type="Admin" />
            <UsersTableBlock users={userList.Editors} type="Editors" />
            <UsersTableBlock users={userList.Other} type="Other" />
          </Table>
        </>
      )}
    </Container>
  );
};

const Container = styled(View)`
  padding: 0.5em;
  margin: 0 auto;
  width: min(100vw, 1000px);
`;
