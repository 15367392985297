import { DecisionPage } from "../../../types/Page";
import { PageRenderer, PageRendererProps } from ".";
import { Button, ButtonGroup, Card, Heading, Icon, ToggleButton, ToggleButtonGroup, View } from "@aws-amplify/ui-react";
import { ReasonsGraph } from "../../../components/ReasonsGraph";
import { Question } from "../../../types/Question";
import { Fragment, useState } from "react";
import { DecisionReplacements } from "../../../components/editor/tools/Replacements/DecisionReplacements";
import { ParagraphWithReplacement } from "../../../components/editor/tools/Replacements/ParagraphWithReplacementRenderer";
import { useAllQuestions } from "../../../hooks/useAllQuestions";
import { BlockRenderer } from "components/editor/tools/BlockRenderer";
import { PageNav } from "./PageNav";
import styled from "@emotion/styled";
import { MdPrint } from "react-icons/md";

export const DecisionPageRenderer: React.FC<PageRendererProps<DecisionPage>> = ({
  page,
  nextPage,
  prevPage,
  pageIndex,
  aid,
  state,
  updateState,
}) => {
  const [decisionValue, setDecisionValue] = useState<number | "?" | undefined>(state?.decisionValue);

  const updateDecision = (val?: string) => {
    if (!val) return;
    const newValue = val === "?" ? "?" : Number.parseInt(val);
    updateState && updateState({ decisionValue: newValue });
    setDecisionValue(newValue);
  };

  const allQuestions = useAllQuestions(
    aid.pages.reduce<Array<Question>>(
      (cur, page) => (page.type === "feelings" ? [...cur, ...page.questions] : cur),
      []
    ),
    state
  );

  const totalValue = allQuestions.reduce((curr, question) => {
    return curr + (state?.questionValues[question.id] || 0);
  }, 0);

  const feelingsOption = Math.abs(totalValue) < 5 ? -1 : totalValue > 0 ? 1 : 0;

  const printBeforePages = aid.pages.slice(0, pageIndex).filter((p) => p.type === "knowledge" && p.print);
  const printAfterPages = aid.pages.slice(pageIndex).filter((p) => p.type === "knowledge" && p.print);

  return (
    <>
      <PrintOnlyContainer>
        {printBeforePages.map((p, i) => (
          <Fragment key={p.id}>
            <Heading level={2}>{p.name}</Heading>
            <PageRenderer pageIndex={i} state={state} aid={aid} page={p} />
          </Fragment>
        ))}
      </PrintOnlyContainer>
      <DecisionContainer>
        <PrintOnlyContainer>
          <Heading level={2}>{page.name}</Heading>
        </PrintOnlyContainer>
        <BlockRenderer data={page.preamble} />
        <DecisionCard variation="outlined">
          <Heading level={3}>{page.decisionTitle}</Heading>
          <BlockRenderer data={page.decisionPrompt} />
          <DecisionButtonGroup
            variation="primary"
            isExclusive={true}
            value={decisionValue?.toString() || []}
            onChange={(v) => updateDecision(Array.isArray(v) ? v[0] : v)}
            justifyContent="center"
          >
            <DecisionButton value="0">{aid.options[0].name}</DecisionButton>
            <ToggleButton value="?">Not Sure</ToggleButton>
            <DecisionButton value="1">{aid.options[1].name}</DecisionButton>
          </DecisionButtonGroup>
        </DecisionCard>
        {decisionValue !== undefined && (
          <>
            <FeelingsDecisionCard variation="outlined">
              <Heading level={3}>{page.feelingTitle}</Heading>
              <ReasonsGraph state={state} questions={allQuestions} values={state?.questionValues || {}} aid={aid} />
            </FeelingsDecisionCard>
            <DecisionCard variation="outlined">
              <Heading level={3}>{page.reflectionTitle}</Heading>
              {decisionValue === "?" && feelingsOption < 0 ? (
                <>
                  {page.reflectionUnsureAndBalanced && (
                    <BlockRenderer
                      renderers={{ paragraph: ParagraphWithReplacement(DecisionReplacements, aid, state) }}
                      data={page.reflectionUnsureAndBalanced}
                    />
                  )}
                </>
              ) : decisionValue === "?" ? (
                <>
                  {page.reflectionUnsure && (
                    <BlockRenderer
                      renderers={{ paragraph: ParagraphWithReplacement(DecisionReplacements, aid, state) }}
                      data={page.reflectionUnsure}
                    />
                  )}
                </>
              ) : feelingsOption < 0 ? (
                <>
                  {page.reflectionBalanced && (
                    <BlockRenderer
                      renderers={{ paragraph: ParagraphWithReplacement(DecisionReplacements, aid, state) }}
                      data={page.reflectionBalanced}
                    />
                  )}
                </>
              ) : feelingsOption === decisionValue ? (
                <>
                  {page.reflectionAligned && (
                    <BlockRenderer
                      renderers={{ paragraph: ParagraphWithReplacement(DecisionReplacements, aid, state) }}
                      data={page.reflectionAligned}
                    />
                  )}
                </>
              ) : (
                <>
                  {page.reflectionNotAligned && (
                    <BlockRenderer
                      renderers={{ paragraph: ParagraphWithReplacement(DecisionReplacements, aid, state) }}
                      data={page.reflectionNotAligned}
                    />
                  )}
                </>
              )}
            </DecisionCard>

            <HideInPrint>
              <ButtonGroup justifyContent="center">
                <Button onClick={() => window.print()}>
                  <Icon as={MdPrint} marginRight="0.5rem" /> Print or Download
                </Button>
              </ButtonGroup>
            </HideInPrint>

            <PageNav
              nextTitle={Boolean(nextPage) ? aid.pages[pageIndex + 1].name : undefined}
              prevTitle={Boolean(prevPage) ? aid.pages[pageIndex - 1].name : undefined}
              canGoNext={Boolean(nextPage)}
              nextPage={nextPage}
              canGoPrev={Boolean(prevPage)}
              prevPage={prevPage}
            />
          </>
        )}
      </DecisionContainer>

      <PrintOnlyContainer>
        {printAfterPages.map((p, i) => (
          <Fragment key={p.id}>
            <Heading level={2}>{p.name}</Heading>
            <PageRenderer pageIndex={i} state={state} aid={aid} page={p} />
          </Fragment>
        ))}
      </PrintOnlyContainer>
    </>
  );
};

const DecisionButton = styled(ToggleButton)`
  flex-basis: 300px;
`;

const DecisionButtonGroup = styled(ToggleButtonGroup)`
  align-items: stretch;

  button {
    text-align: center;
  }
`;

const DecisionCard = styled(Card)`
  margin-bottom: 1rem;
`;

const FeelingsDecisionCard = styled(DecisionCard)`
  break-inside: avoid;
`;

const DecisionContainer = styled(View)`
  h3 {
    font-size: 1.5em;
    margin-bottom: 0.5rem;
  }
  @media print {
    -webkit-print-color-adjust: exact !important;
  }
`;

const HideInPrint = styled(View)`
  @media print {
    display: none;
  }
`;

const PrintOnlyContainer = styled(View)`
  display: none;
  @media print {
    display: block;
    -webkit-print-color-adjust: exact !important;
  }
`;
