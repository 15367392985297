import { Navigate, Route, Routes, useMatch, useNavigate, useParams } from "react-router-dom";
import { useDecisionAid } from "../../hooks/useDecisionAids";
import styled from "@emotion/styled";
import { Button, Flex, Heading, Icon, Menu, MenuButton, MenuItem, View } from "@aws-amplify/ui-react";
import { PageRenderer } from "./PageRenderer";
import { useEffect, useRef, useState } from "react";
import { DecisionAidState } from "../../types/DecisionAid";
import { MdChevronLeft, MdChevronRight, MdMenu } from "react-icons/md";
import { Helmet } from "react-helmet-async";

interface Props {}

export const DecisionAid: React.FC<Props> = () => {
  const { id } = useParams();
  const [aid, error] = useDecisionAid(id || "");
  const [state, setState] = useState<DecisionAidState>({
    questionValues: {},
    questionOverrides: {},
    extraQuestions: [],
    deletedQuestions: [],
    blockStates: {
      checklist: {},
      textbox: {},
    },
  });
  const matches = useMatch(`aid/:aidid/page/:pageid`);
  const [pageIndex, setPageIndex] = useState<number>();
  const navigate = useNavigate();
  const contentScroller = useRef<HTMLDivElement>(null);
  const pages = aid?.pages.filter((p) => p.hidden !== true);

  useEffect(() => {
    if (pages) {
      let index = pages.findIndex((p) => p.id === matches?.params?.pageid);
      index = index < 0 ? 0 : index;
      setPageIndex(index);
    }
  }, [pages, pageIndex, matches?.params?.pageid]);

  if (!id || error) {
    return <Navigate to="../" />;
  }

  if (!aid || !pages) {
    return <></>;
  }

  const goToPage = (index: number) => {
    setPageIndex(index);
    navigate(`page/${pages[index].id}`);
    if (contentScroller.current) {
      contentScroller.current.scrollTo(0, 0);
    }
  };

  const updateState = (update: Partial<DecisionAidState>) => {
    setState((curr) => ({
      ...curr,
      ...update,
    }));
  };

  if (pageIndex === undefined) {
    return <></>;
  }

  return (
    <OuterContainer className="decision-aid">
      <Helmet>
        <title>{aid.title}</title>
      </Helmet>
      <View>
        <Heading level={1}>{aid.title}</Heading>
      </View>
      <NavContainer progress={((100 * pageIndex) / pages.length).toString()} current={(100 / pages.length).toString()}>
        <Button size="small" variation="menu" isDisabled={pageIndex <= 0} onClick={() => goToPage(pageIndex - 1)}>
          <Icon as={MdChevronLeft} />
        </Button>
        <MenuContainer>
          <Menu
            menuAlign="center"
            trigger={
              <MenuButton textAlign="center" size="small" variation="menu">
                <Icon as={MdMenu} marginRight="0.5em" />
                {pages[pageIndex].name}
              </MenuButton>
            }
          >
            {pages.map((page, i) => (
              <ActivatableMenuItem
                key={page.id}
                active={Boolean(i === pageIndex).toString()}
                isDisabled={i === pageIndex}
                onClick={() => goToPage(i)}
              >
                {page.name}
              </ActivatableMenuItem>
            ))}
          </Menu>
        </MenuContainer>
        <Button
          size="small"
          variation="menu"
          isDisabled={pageIndex >= pages.length - 1}
          onClick={() => goToPage(pageIndex + 1)}
        >
          <Icon as={MdChevronRight} />
        </Button>
      </NavContainer>
      <ContentContainer ref={contentScroller}>
        <Routes>
          <Route
            path={`page/:pageid`}
            element={
              <PageRenderer
                pageIndex={pageIndex}
                nextPage={pageIndex < pages.length - 1 ? () => goToPage(pageIndex + 1) : undefined}
                prevPage={pageIndex > 0 ? () => goToPage(pageIndex - 1) : undefined}
                aid={aid}
                state={state}
                updateState={updateState}
              />
            }
          />
          <Route
            index
            element={
              <PageRenderer
                pageIndex={pageIndex}
                nextPage={() => goToPage(1)}
                page={pages[0]}
                aid={aid}
                state={state}
                updateState={updateState}
              />
            }
          />
        </Routes>
      </ContentContainer>
    </OuterContainer>
  );
};

const ActivatableMenuItem = styled(MenuItem)<{ active: string }>`
  justify-content: center;
  ${({ active }) =>
    active === "true" &&
    `background-color: var(--amplify-colors-brand-primary-60);
    color: var(--amplify-colors-neutral-10);

    &:hover { background-color: var(--amplify-colors-brand-primary-60);
      color: var(--amplify-colors-neutral-10);

    }
  `}
`;

const MenuContainer = styled(View)`
  position: relative;
`;

const NavContainer = styled(Flex)<{ progress: string; current: string }>`
  justify-content: space-between;
  border-bottom: 3px solid var(--amplify-colors-neutral-20);
  padding-bottom: 0.25rem;
  position: relative;

  ::before,
  ::after {
    position: absolute;
    content: "";
    bottom: -3px;
    height: 3px;
  }

  ::before {
    left: ${({ progress }) => progress}%;
    width: ${({ current }) => current}%;
    border-bottom: 3px solid var(--amplify-colors-brand-primary-60);
    transition: left 500ms ease-in-out;
  }

  ::after {
    left: 0;
    width: ${({ progress }) => progress}%;
    border-bottom: 3px solid var(--amplify-colors-brand-primary-20);
    transition: width 500ms ease-in-out;
  }

  @media print {
    display: none;
  }
`;

const ContentContainer = styled(View)`
  flex-grow: 1;
  overflow: scroll;

  @media print {
    overflow: initial;
  }
`;

const OuterContainer = styled(Flex)`
  width: min(100vw, 1000px);
  height: 100vh;
  height: 100dvh;
  margin: 0 auto;
  padding: 1em;
  gap: 0.5rem;
  flex-direction: column;

  h1 {
    font-size: 2em;
    text-align: center;
  }

  @media print {
    h1 {
      font-size: 2.5em;
    }
    h2 {
      font-size: 1.75em;
      margin-top: 1rem;
    }
  }
`;
