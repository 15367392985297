import { TableBody, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react";
import { User } from "api/users";
import { UserRow } from "./UserRow";

interface Props {
  users: Array<User>;
  type: "Admin" | "Editors" | "Other";
}

export const UsersTableBlock: React.FC<Props> = ({ type, users }) => {
  if (users.length === 0) return <></>;
  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell as="th">{type}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user) => (
          <UserRow type={type} user={user} key={user.Username} />
        ))}
      </TableBody>
    </>
  );
};
