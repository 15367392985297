import { IconChevronDown, IconQuestion, IconTableWithHeadings } from "@codexteam/icons";

class QandA {
  static get toolbox() {
    return {
      title: "Q and A",
      icon: IconQuestion,
    };
  }

  constructor({ api, data }) {
    this.api = api;
    this.data = data;
    this.container = null;

    this.settings = [
      {
        name: "accordion",
        label: this.api.i18n.t("Accordion"),
        icon: IconChevronDown,
        default: true,
      },
      {
        name: "flipCards",
        label: this.api.i18n.t("Flip Cards"),
        icon: IconTableWithHeadings,
        default: false,
      },
    ];
  }

  get CSS() {
    return {
      container: "block-qanda",
      item: "block-qanda-item",
      question: "question-input",
      answer: "answer-input",
    };
  }

  buildItem(question, answer) {
    const innerConainer = document.createElement("div");
    innerConainer.classList.add(this.CSS.item);

    const questionInput = document.createElement("div");
    questionInput.classList.add(this.CSS.question);
    questionInput.classList.add("cdx-input");
    questionInput.classList.add("cdx-input-placeholder");
    questionInput.setAttribute("contenteditable", "true");
    questionInput.innerHTML = question;
    questionInput.dataset.placeholder = "Enter your question here";
    questionInput.dataset.label = "Q: ";
    questionInput.addEventListener("paste", (event) => {
      event.stopPropagation();
    });
    innerConainer.appendChild(questionInput);

    const answerInput = document.createElement("p");
    answerInput.classList.add(this.CSS.answer);
    answerInput.classList.add("cdx-input");
    answerInput.classList.add("cdx-input-placeholder");
    answerInput.setAttribute("contenteditable", "true");
    answerInput.innerHTML = answer;
    answerInput.dataset.placeholder = "Enter your answer here";
    answerInput.dataset.label = "A: ";
    answerInput.addEventListener("paste", (event) => {
      event.stopPropagation();
    });
    innerConainer.appendChild(answerInput);

    this.container.appendChild(innerConainer);
    return questionInput;
  }

  render() {
    const container = document.createElement("div");
    container.classList.add(this.CSS.container);
    this.container = container;

    const items = this.data.items ?? [{ question: this.data.question || "", answer: this.data.answer || "" }];

    items.forEach(({ question, answer }) => {
      this.buildItem(question, answer);
    });

    container.addEventListener("keydown", (event) => {
      const [ENTER, BACKSPACE] = [13, 8]; // key codes

      switch (event.keyCode) {
        case ENTER:
          this.handleEnter(event);
          break;
        case BACKSPACE:
          this.backspace(event);
          break;
        default:
          break;
      }
    });

    return container;
  }

  get currentItem() {
    let currentNode = window.getSelection().anchorNode;

    if (currentNode.nodeType !== Node.ELEMENT_NODE) {
      currentNode = currentNode.parentNode;
    }

    return currentNode.closest(`.${this.CSS.item}`);
  }

  get currentInput() {
    let currentNode = window.getSelection().anchorNode;

    if (currentNode.nodeType !== Node.ELEMENT_NODE) {
      currentNode = currentNode.parentNode;
    }

    return currentNode.closest(`.${this.CSS.question}, .${this.CSS.answer}`);
  }

  get isCurrentlyInQuestion() {
    let currentNode = window.getSelection().anchorNode;

    if (currentNode.nodeType !== Node.ELEMENT_NODE) {
      currentNode = currentNode.parentNode;
    }

    return currentNode.closest(`.${this.CSS.question}`) != null;
  }

  handleEnter(event) {
    const items = this.container.querySelectorAll("." + this.CSS.item);
    const currentItem = this.currentItem;

    if (this.isCurrentlyInQuestion) {
      currentItem.querySelector("." + this.CSS.answer).focus();
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    const lastItem = items[items.length - 1];

    const currentQuestion = currentItem.querySelector("." + this.CSS.question);
    const currentAnswer = currentItem.querySelector("." + this.CSS.answer);

    if (!currentQuestion.innerHTML.trim().length && !currentAnswer.innerHTML.trim().length) {
      if (currentItem === lastItem) {
        const currentIndex = this.api.blocks.getCurrentBlockIndex();
        if (items.length === 1) {
          this.api.blocks.delete(currentIndex);
        }
        currentItem.parentElement.removeChild(currentItem);
        this.api.blocks.insert();
        this.api.caret.setToBlock(currentIndex);
      } else {
        items[Array.from(items).indexOf(currentItem) + 1].focus();
        currentItem.parentElement.removeChild(currentItem);
      }
      event.preventDefault();
      event.stopPropagation();

      return;
    }

    this.buildItem("", "").focus();
    event.preventDefault();
    event.stopPropagation();

    return;
  }

  /**
   * Handle backspace
   *
   * @param {KeyboardEvent} event
   */
  backspace(event) {
    const items = this.container.querySelectorAll("." + this.CSS.item);
    const currentItem = this.currentItem;

    if (!this.isCurrentlyInQuestion) {
      if (this.currentInput.innerHTML.length === 0) {
        currentItem.querySelector("." + this.CSS.question).focus();
        event.preventDefault();
        event.stopPropagation();
      }
      return;
    }

    const currentQuestion = currentItem.querySelector("." + this.CSS.question);
    const currentAnswer = currentItem.querySelector("." + this.CSS.answer);

    if (!currentQuestion.innerHTML.trim().length && !currentAnswer.innerHTML.trim().length) {
      if (items.length === 1) return;
      const currentItemIndex = Array.from(items).indexOf(currentItem);
      if (currentItemIndex === 0) {
        items[1].querySelector("." + this.CSS.question).focus();
      } else {
        items[currentItemIndex - 1].querySelector("." + this.CSS.answer).focus();
      }
      currentItem.parentElement.removeChild(currentItem);
      event.preventDefault();
      event.stopPropagation();
    }
  }

  renderSettings() {
    return this.settings.map((item) => ({
      ...item,
      isActive: this.data.style === item.name || (!this.data.style && item.default),
      closeOnActivate: true,
      onActivate: () => this.toggleTune(item.name),
    }));
  }

  toggleTune(style) {
    this.data.style = style;
    this.api.saver.save();
  }

  save() {
    const qandas = Array.from(this.container.querySelectorAll("." + this.CSS.item));
    return {
      style: this.data.style,
      items: qandas.map((qanda) => {
        const question = qanda.querySelector("." + this.CSS.question);
        const answer = qanda.querySelector("." + this.CSS.answer);
        return {
          question: question?.innerHTML || "",
          answer: answer?.innerHTML || "",
        };
      }),
    };
  }
}

export default QandA;
