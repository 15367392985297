import HTMLReactParser from "html-react-parser";
import { RenderFn } from "editorjs-blocks-react-renderer";
import { ImageBlockConfig, ImageBlockData } from "editorjs-blocks-react-renderer/dist/renderers/image";

interface ExpandedImageBlockData extends ImageBlockData {
  alt: string;
}

export const ImageRenderer: RenderFn<ExpandedImageBlockData, ImageBlockConfig | undefined> = ({
  data,
  className = "",
  actionsClassNames = {
    stretched: "image-block--stretched",
    withBorder: "image-block--with-border",
    withBackground: "image-block--with-background",
  },
}) => {
  const classNames: string[] = [];
  if (className) classNames.push(className);

  Object.keys(actionsClassNames).forEach((actionName) => {
    if (data && data[actionName] === true && actionName in actionsClassNames) {
      // @ts-ignore
      classNames.push(actionsClassNames[actionName]);
    }
  });

  const figureprops: {
    [s: string]: string;
  } = {};

  if (classNames.length > 0) {
    figureprops.className = classNames.join(" ");
  }

  const url = data?.file?.url || data?.url;

  return (
    <figure {...figureprops}>
      {url && <img src={url} alt={data.alt || data.caption || data?.file?.name} />}
      {data?.caption && <figcaption>{HTMLReactParser(data.caption)}</figcaption>}
    </figure>
  );
};
