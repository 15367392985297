import { DecisionAid, DecisionAidState } from "../../../../types/DecisionAid";
import { ReplacementTool } from "./Replacements";

interface ReplacementOptionBase {
  id: string;
  label: string;
}

export interface ReplacementOption extends ReplacementOptionBase {
  text?: (aid: DecisionAid) => string;
  resolve?: (aid: DecisionAid, state: DecisionAidState | undefined) => JSX.Element;
}

interface EditorReplacementOption extends ReplacementOptionBase {
  text?: string;
}

export const ReplacementFactory = (options: Array<ReplacementOption>, aid: DecisionAid) => {
  const processedOptions = options.map<EditorReplacementOption>(({ id, label, text }) => ({
    id,
    label,
    text: text ? text(aid) : undefined,
  }));
  return ReplacementTool(processedOptions);
};
