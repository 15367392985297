import { ReactNode, useEffect } from "react";
import { DecisionAidPage, DecisionAidPageUpdate, DecisionPage, FeelingsPage, KnowledgePage } from "../../../types/Page";
import { DecisionPageEditor } from "./DecisionPageEditor";
import { FeelingsPageEditor } from "./FeelingsPageEditor";
import { KnowledgePageEditor } from "./KnowledgePageEditor";
import { DecisionAid } from "../../../types/DecisionAid";
import { useParams, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  ButtonGroup,
  CheckboxField,
  Flex,
  Icon,
  TextField,
  ToggleButton,
  View,
} from "@aws-amplify/ui-react";
import styled from "@emotion/styled";
import { MdContentCopy, MdOpenInNew, MdPrint } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";

interface EditorProps {
  aid: DecisionAid;
}

export interface PageEditorProps<TPage extends DecisionAidPage> extends EditorProps {
  page: TPage;
  onUpdate: (update: DecisionAidPageUpdate<TPage>) => void;
  onUpdateAid: (updates: Partial<Omit<DecisionAid, "id">>) => void;
}

type PageEditors = {
  [PageType in DecisionAidPage as PageType["type"]]: (props: PageEditorProps<DecisionAidPage>) => ReactNode;
};

const Editors: PageEditors = {
  knowledge: ({ page, ...props }) => <KnowledgePageEditor page={page as KnowledgePage} {...props} />,
  decision: ({ page, ...props }) => <DecisionPageEditor page={page as DecisionPage} {...props} />,
  feelings: ({ page, ...props }) => <FeelingsPageEditor page={page as FeelingsPage} {...props} />,
};

interface Props extends EditorProps {
  aid: DecisionAid;
  onUpdate: (id: string, newPage: DecisionAidPageUpdate) => void;
  onUpdateAid: (updates: Partial<Omit<DecisionAid, "id">>) => void;
}

export const PageEditor: React.FC<Props> = ({ aid, onUpdateAid, onUpdate, ...props }) => {
  const { pageid } = useParams();
  const navigate = useNavigate();
  const page = aid.pages.find((p) => p.id === pageid);

  useEffect(() => {
    if (!page) {
      navigate("../");
    }
  }, [page, navigate]);

  function clonePage() {
    if (!page) return;
    const index = aid.pages.findIndex((p) => p.id === page.id);
    const id = uuidv4();
    const newpages = [...aid.pages];
    newpages.splice(index + 1, 0, {
      ...structuredClone(page),
      id,
      name: page.name.replace(/ \(Copy (\d+)\)$|$/, (_, o) => ` (Copy ${(Number.parseInt(o) || 0) + 1})`),
    });
    onUpdateAid({ pages: newpages });
  }

  if (!page) return <></>;

  return (
    <Container direction="column" grow="1">
      <TextField label="Page Name" value={page.name} onChange={(e) => onUpdate(page.id, { name: e.target.value })} />
      <CheckboxField
        label="Hide this page"
        name="hidden"
        checked={page.hidden === true}
        value="yes"
        onChange={(e) => onUpdate(page.id, { hidden: e.target.checked })}
      />
      <ButtonGroup>
        <Button onClick={() => window.open(`/aid/${aid.id}/page/${page.id}`, "_blank")}>
          <Icon as={MdOpenInNew} marginRight="0.5rem" /> View
        </Button>
        {page.type === "knowledge" && (
          <Button onClick={clonePage}>
            <Icon as={MdContentCopy} marginRight="0.5rem" /> Clone
          </Button>
        )}
        {page.type === "knowledge" && (
          <>
            <ToggleButton
              isPressed={Boolean(page.print)}
              onChange={() =>
                onUpdate(page.id, { print: !Boolean(page.print) } as DecisionAidPageUpdate<KnowledgePage>)
              }
            >
              <Icon as={MdPrint} marginRight="0.5rem" />
              Print {page.print ? "On" : "Off"}
            </ToggleButton>
            <Alert
              marginLeft="calc(var(--amplify-components-flex-gap) * -1)"
              style={{ zIndex: "-1" }}
              fontSize="0.7em"
              width="14em"
              padding="0.5rem"
            >
              Make this page printable in the final summary
            </Alert>
          </>
        )}
      </ButtonGroup>
      <InnerContainer>
        {Editors[page.type]({ page, onUpdateAid, onUpdate: onUpdate.bind(null, page.id), aid, ...props })}
      </InnerContainer>
    </Container>
  );
};

const Container = styled(Flex)`
  height: 100%;
`;

const InnerContainer = styled(View)`
  flex-grow: 1;
`;
