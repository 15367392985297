import { Auth } from "aws-amplify";
import { useState } from "react";
import { useEffectOnce } from "usehooks-ts";

interface UserInfo {
  id: string;
  username: string;
}

export const useUserInfo = (): UserInfo | undefined => {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  useEffectOnce(() => {
    Auth.currentUserInfo().then((info) => {
      setUserInfo(info);
    });
  });
  return userInfo;
};
