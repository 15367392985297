import { Button, Flex, Icon, Text } from "@aws-amplify/ui-react";
import styled from "@emotion/styled";
import { ReactNode } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

interface Props {
  canGoPrev: boolean;
  canGoNext: boolean;
  prevPage?: () => void;
  nextPage?: () => void;
  nextTitle?: string;
  prevTitle?: string;
  children?: ReactNode;
}

export const PageNav: React.FC<Props> = ({
  nextTitle,
  prevTitle,
  canGoNext,
  canGoPrev,
  nextPage,
  prevPage,
  children,
}) => {
  return (
    <NavGroup marginTop="0.5em" gap="0.25em">
      <NavContainer alignItems="start">
        <NavButton border="0" isDisabled={!canGoPrev} onClick={prevPage}>
          <Icon as={MdChevronLeft} />
          Prev
        </NavButton>
        {prevTitle && <Title>{prevTitle}</Title>}
      </NavContainer>
      {children}
      <NavContainer alignItems="end">
        <NavButton border="0" isDisabled={!canGoNext} variation="primary" onClick={nextPage}>
          Next
          <Icon as={MdChevronRight} />
        </NavButton>
        {nextTitle && <Title textAlign="right">{nextTitle}</Title>}
      </NavContainer>
    </NavGroup>
  );
};

const NavContainer = styled(Flex)`
  flex-direction: column;
  gap: 0.2rem;
`;

const Title = styled(Text)`
  font-size: 0.8em;
  color: var(--amplify-colors-neutral-80);
  max-width: 35vw;
  text-overflow: ellipsis;
`;

const NavGroup = styled(Flex)`
  justify-content: space-between;
  align-items: start;

  @media print {
    display: none;
  }
`;

const NavButton = styled(Button)`
  padding: 0.25em;
  margin: 0;
`;
