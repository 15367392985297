import { Button, Flex, Icon, ToggleButton, View } from "@aws-amplify/ui-react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MdDelete, MdDragHandle, MdRectangle } from "react-icons/md";
import styled from "@emotion/styled";

interface Props {
  name: string;
  id: string;
  active?: boolean;
  onClick: () => void;
  isDisabled?: boolean;
  onDelete?: () => void;
}

export const SortableToggleMenuButton: React.FC<Props> = ({ onDelete, isDisabled, id, name, active, onClick }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <OuterContainer ref={setNodeRef} style={style} {...attributes}>
      <Container>
        {isDisabled ? (
          <Button variation="link" size="small" isDisabled>
            <Icon as={MdRectangle} />
          </Button>
        ) : (
          <DragButton variation="link" size="small" {...listeners}>
            <Icon as={MdDragHandle} />
          </DragButton>
        )}

        <ToggleButton variation="menu" isFullWidth onClick={onClick} isPressed={active}>
          {name}
        </ToggleButton>

        {onDelete && (
          <Button className="delete-button" variation="destructive" onClick={onDelete}>
            <Icon as={MdDelete} />
          </Button>
        )}
      </Container>
    </OuterContainer>
  );
};

const DragButton = styled(Button)`
  cursor: grab;
  :active {
    cursor: grabbing !important;
  }
  :focus {
    box-shadow: none;
  }
`;

const OuterContainer = styled(View)`
  overflow: hidden;
`;

const Container = styled(Flex)`
  --delete-width: 2em;
  gap: 0.2em;

  :has(.delete-button) {
    margin-right: calc(-0.3em - var(--delete-width));
    transition: margin-right 300ms ease-in-out;
  }

  .delete-button {
    width: var(--delete-width);
  }

  &:hover {
    margin-right: 0;
  }
`;
