import { Expander, ExpanderItem, Flex, View } from "@aws-amplify/ui-react";
import styled from "@emotion/styled";
import { RenderFn } from "editorjs-blocks-react-renderer";
import HTMLReactParser from "html-react-parser";
import { useState } from "react";

export interface QandABlockData {
  items?: Array<{
    question: string;
    answer: string;
  }>;
  question?: string;
  answer?: string;
  style?: "accordion" | "flipCards";
}

export const QandARenderer: RenderFn<QandABlockData> = ({
  data: { items, question, answer, style },
  className = "",
}) => {
  const [flipStates, setFlipStates] = useState<Array<boolean>>(items?.map(() => false) || []);

  const doFlip = (index: number) => {
    console.log("flip");
    setFlipStates((current: Array<boolean>) => {
      return current.map((v, i) => (i === index ? !v : v));
    });
  };
  console.log(flipStates);

  return (
    <View padding="0.5rem">
      {style === "flipCards" ? (
        <FlipcardContainer>
          {items &&
            items.map(({ question, answer }, i) => (
              <Flipcard key={i} onClick={() => doFlip(i)} data-flipped={flipStates[i]}>
                <FlipCardInner>
                  <FlipcardFront>{HTMLReactParser(question)}</FlipcardFront>
                  <FlipcardBack>{HTMLReactParser(answer)}</FlipcardBack>
                </FlipCardInner>
              </Flipcard>
            ))}
          {question && answer && (
            <Flipcard>
              <FlipCardInner>
                <FlipcardFront>{HTMLReactParser(question)}</FlipcardFront>
                <FlipcardBack>{HTMLReactParser(answer)}</FlipcardBack>
              </FlipCardInner>
            </Flipcard>
          )}
        </FlipcardContainer>
      ) : (
        <Expander isCollapsible>
          {items &&
            items.map(({ question, answer }, i) => (
              <ExpanderItem value={`item-${i + 1}`} key={i} title={<div>{HTMLReactParser(question)}</div>}>
                {HTMLReactParser(answer)}
              </ExpanderItem>
            ))}
          {question && answer && (
            <ExpanderItem value={`item-extra`} title={<div>{HTMLReactParser(question)}</div>}>
              {HTMLReactParser(answer)}
            </ExpanderItem>
          )}
        </Expander>
      )}
    </View>
  );
};

const FlipcardContainer = styled(Flex)`
  flex-wrap: wrap;
  container-type: inline-size;
  justify-content: center;
`;

const Flipcard = styled(View)`
  --size: clamp(300px, 300px, 80vw);
  width: var(--size);
  height: var(--size);
  position: relative;
  perspective: 1000px;
  cursor: pointer;

  :hover > div {
    transform: rotateY(5deg);
  }

  &[data-flipped="true"] > div {
    transform: rotateY(180deg);
  }

  &[data-flipped="true"]:hover > div {
    transform: rotateY(175deg);
  }
`;

const FlipCardInner = styled(View)`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
`;

const FlipcardSide = styled(View)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  .expand-to-outer {
    padding: 1rem;
  }
`;

const FlipcardFront = styled(FlipcardSide)`
  background-color: var(--amplify-colors-brand-primary-60);
  color: white;
  font-size: 2em;
`;

const FlipcardBack = styled(FlipcardSide)`
  transform: rotateY(180deg);
  background-color: var(--amplify-colors-brand-primary-20);
`;
