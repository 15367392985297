import { useAllQuestions } from "../../../../hooks/useAllQuestions";
import { DecisionAid, DecisionAidState } from "../../../../types/DecisionAid";
import { Question } from "../../../../types/Question";
import { ReplacementOption } from "./ReplacementFactory";

const MIN_VAL = 5;

interface ReasonsForProps {
  aid: DecisionAid;
  state?: DecisionAidState;
  option?: number | "?";
}

const ReasonsFor: React.FC<ReasonsForProps> = ({ aid, state, option }) => {
  const allQuestions = useAllQuestions(
    aid.pages.reduce<Array<Question>>(
      (cur, page) => (page.type === "feelings" ? [...cur, ...page.questions] : cur),
      []
    ),
    state
  );

  if (option === undefined || option === "?") {
    return <>N/A</>;
  }

  const questionsFor = allQuestions
    .filter((question) => {
      const value = state?.questionValues[question.id];
      if (!value || value === 0) return false;
      const choseFirstOption = option === 0;
      const questionIsFirstOption = value < 0;
      return choseFirstOption === questionIsFirstOption;
    })
    .sort((a, b) => Math.abs(state?.questionValues[b.id] || 0) - Math.abs(state?.questionValues[a.id] || 0));

  return (
    <ul>
      {questionsFor.map((question) => (
        <li key={question.id}>{question.sides[option].title}</li>
      ))}
    </ul>
  );
};

export const OptionForValue = (value: number): number | "?" => {
  if (Math.abs(value) < MIN_VAL) {
    return "?";
  }
  return value < 0 ? 0 : 1;
};

const LabelFor: React.FC<ReasonsForProps> = ({ aid, option }) => {
  if (option === undefined) return <>Unclear</>;
  if (option === "?") return <>unsure</>;
  return <>{aid.options[option].name}</>;
};

const OppositeOption = (current: number | "?" | undefined): number | "?" | undefined => {
  switch (current) {
    case undefined:
      return undefined;
    case 0:
      return 1;
    case 1:
      return 0;
    case "?":
      return "?";
  }
  return undefined;
};

const OptionForValues = (values: Array<number>): number | "?" | undefined => {
  const sum = values.reduce((curr, v) => v + curr, 0);
  if (sum === 0) return undefined;
  return sum < 0 ? 0 : 1;
};

export const DecisionReplacements: Array<ReplacementOption> = [
  {
    id: "userDecision",
    label: "Selected Option",
    resolve: (aid, state) => <LabelFor aid={aid} state={state} option={state?.decisionValue} />,
  },
  {
    id: "userReasonsFor",
    label: "Reasons for selected option (shown in bulleted list)",
    resolve: (aid, state) => <ReasonsFor aid={aid} state={state} option={state?.decisionValue} />,
  },
  {
    id: "otherDecision",
    label: "Unselected Option",
    resolve: (aid, state) => <LabelFor aid={aid} state={state} option={OppositeOption(state?.decisionValue)} />,
  },
  {
    id: "userReasonsAgainst",
    label: "Reasons for unselected option (shown in bulleted list)",
    resolve: (aid, state) => <ReasonsFor aid={aid} state={state} option={OppositeOption(state?.decisionValue)} />,
  },
  {
    id: "toolDecision",
    label: "Value-aligned option",
    resolve: (aid, state) => (
      <LabelFor aid={aid} state={state} option={OptionForValues(Object.values(state?.questionValues || {}))} />
    ),
  },
  {
    id: "toolDecisionReasons",
    label: "Reasons for value-aligned option",
    resolve: (aid, state) => (
      <ReasonsFor aid={aid} state={state} option={OptionForValues(Object.values(state?.questionValues || {}))} />
    ),
  },
  {
    id: "toolNonDecision",
    label: "Value-unaligned option",
    resolve: (aid, state) => (
      <LabelFor
        aid={aid}
        state={state}
        option={OppositeOption(OptionForValues(Object.values(state?.questionValues || {})))}
      />
    ),
  },
  {
    id: "toolNonDecisionReasons",
    label: "Reasons for value-unaligned option",
    resolve: (aid, state) => (
      <ReasonsFor
        aid={aid}
        state={state}
        option={OppositeOption(OptionForValues(Object.values(state?.questionValues || {})))}
      />
    ),
  },
  {
    id: "option1",
    label: "Option 1",
    resolve: (aid, state) => <LabelFor aid={aid} state={state} option={0} />,
  },
  {
    id: "reasonsForOption1",
    label: "Reasons for Option 1",
    resolve: (aid, state) => <ReasonsFor aid={aid} state={state} option={0} />,
  },
  {
    id: "option2",
    label: "Option 2",
    resolve: (aid, state) => <LabelFor aid={aid} state={state} option={1} />,
  },
  {
    id: "reasonsForOption2",
    label: "Reasons for Option 2",
    resolve: (aid, state) => <ReasonsFor aid={aid} state={state} option={1} />,
  },
];
