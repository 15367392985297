import { DecisionAid } from "../types/DecisionAid";
import { loadDecisionAid, loadDecisionAids } from "../api/decisionAids";
import { useFetchData } from "./useFetchData";
import { useCallback } from "react";

export const useDecisionAids = () => {
  const aids = useFetchData<Array<DecisionAid>>(loadDecisionAids);
  return aids;
};

export const useDecisionAid = (id: string) => {
  const load = useCallback(async () => {
    return loadDecisionAid(id);
  }, [id]);
  const aid = useFetchData<DecisionAid>(load);
  return aid;
};
