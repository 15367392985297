import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import Table from "@editorjs/table";
import Quote from "@editorjs/quote";
import NestedList from "@editorjs/nested-list";
import QandA from "./tools/QandA/QandA";
import SimpleImage from "./tools/image";
import Embed from "@editorjs/embed";
import ColorPlugin from "editorjs-text-color-plugin";
import { QuestionColours, colourFromName } from "components/ColouredButtons";
import Checklist from "@editorjs/checklist";
import Hyperlink from "editorjs-hyperlink";
import { HTMLEditor } from "./tools/HTML/HTMLEditor";
import { TextBox } from "./tools/TextBox/TextBox";
export const INLINE_TOOLS = ["bold", "italic", "hyperlink", "color", "marker", "html"];

export const EDITOR_TOOLS = {
  textbox: TextBox,
  html: HTMLEditor,
  hyperlink: {
    class: Hyperlink,
    config: {
      shortcut: "CMD+K",
      target: "_blank",
      rel: "nofollow",
      availableTargets: ["", "_blank"],
      availableRels: ["", "nofollow"],
    },
  },
  marker: {
    class: ColorPlugin,
    config: {
      type: "text",
      colorCollections: [
        ...QuestionColours.map((c) => colourFromName(c, "100")),
        ...QuestionColours.map((c) => colourFromName(c, "90")),
        ...QuestionColours.map((c) => colourFromName(c, "80")),
        ...QuestionColours.map((c) => colourFromName(c, "60")),
        ...QuestionColours.map((c) => colourFromName(c, "40")),
        ...QuestionColours.map((c) => colourFromName(c, "20")),
        ...QuestionColours.map((c) => colourFromName(c, "10")),
      ],
      customPicker: true,
    },
  },
  color: {
    class: ColorPlugin,
    config: {
      type: "marker",
      colorCollections: [
        ...QuestionColours.map((c) => colourFromName(c, "10")),
        ...QuestionColours.map((c) => colourFromName(c, "20")),
        ...QuestionColours.map((c) => colourFromName(c, "40")),
      ],
      customPicker: true,
    },
  },
  header: {
    class: Header,
    inlineToolbar: INLINE_TOOLS,
  },
  image: {
    class: SimpleImage as any,
    inlineToolbar: INLINE_TOOLS,
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar: INLINE_TOOLS,
  },
  table: {
    class: Table,
    inlineToolbar: INLINE_TOOLS,
  },
  quote: {
    class: Quote,
    inlineToolbar: INLINE_TOOLS,
  },
  list: {
    class: NestedList,
    inlineToolbar: INLINE_TOOLS,
  },
  qanda: {
    class: QandA as any,
    inlineToolbar: INLINE_TOOLS,
  },
  embed: Embed,
  checklist: {
    class: Checklist,
    inlineToolbar: INLINE_TOOLS,
  },
};
