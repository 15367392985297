import { DecisionAidPage } from "./Page";
import { PartialQuestion, Question } from "./Question";
import { v4 as uuidv4 } from "uuid";

export interface DecisionAidOption {
  name: string;
}

export interface DecisionAid {
  id: string;
  name: string;
  userId?: any;
  title: string;
  pages: Array<DecisionAidPage>;
  options: Array<DecisionAidOption>;
  defaultNewQuestion: Omit<Question, "id">;
}

export type ChecklistBlockState = Array<boolean>;

export interface BlockStates {
  checklist: {
    [blockId: string]: ChecklistBlockState;
  };
  textbox: {
    [blockId: string]: string;
  };
}

export interface DecisionAidState {
  questionOverrides: {
    [questionId: string]: PartialQuestion;
  };
  extraQuestions: Array<Question>;
  deletedQuestions: Array<string>;
  questionValues: {
    [questionId: string]: number;
  };
  decisionValue?: "?" | number;
  blockStates: BlockStates;
}

export const createNewQuestion = (aid: DecisionAid, label: string | number, isExtra: boolean): Question => {
  const id = uuidv4();
  const newQuestion: Question = {
    ...structuredClone(aid.defaultNewQuestion),
    id,
    isExtra,
  };
  if (Number.isFinite(label)) {
    newQuestion.label += ` ${label}`;
  } else {
    newQuestion.label = `${label}`;
  }
  newQuestion.sides.forEach((s, i) => {
    s.title += ` ${aid.options[i].name}`;
  });
  return newQuestion;
};
