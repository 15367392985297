import { OutputBlockData, OutputData } from "@editorjs/editorjs";
import { RenderersProp } from "editorjs-blocks-react-renderer";
import { ConfigProp } from "editorjs-blocks-react-renderer";
import Blocks, { DataProp } from "editorjs-blocks-react-renderer";
import { QandARenderer } from "./QandA/QandARenderer";
import { ChecklistBlockData, ChecklistRenderer } from "./ChecklistRenderer";
import { ImageRenderer } from "./image/ImageRenderer";
import { BlockStates } from "types/DecisionAid";
import { TextBoxBlockData, TextBoxRenderer } from "./TextBox/TextBoxRenderer";

interface Props {
  data?: OutputData;
  config?: ConfigProp | undefined;
  renderers?: RenderersProp | undefined;
  state?: BlockStates;
  setState?: (changes: Partial<BlockStates>) => void;
}

export const BlockRenderer: React.FC<Props> = ({ state, setState, data, config, renderers }) => {
  if (!data) return <></>;

  function stateWith(id: string, index: number, value: boolean, blockData: ChecklistBlockData): Array<boolean> {
    if (!state) return [];
    let valArray = state.checklist[id];
    if (!valArray) {
      valArray = blockData.items.map((item) => item.checked ?? false);
    }
    valArray[index] = value;
    return valArray;
  }

  function makeChecklistBlockData(block: OutputBlockData<string, any>) {
    const checklistBlockData = block.data as ChecklistBlockData;
    const blockId = block.id || "";

    return {
      ...block,
      data: {
        ...checklistBlockData,
        items: checklistBlockData.items.map((item, i) => ({
          ...item,
          checked: state?.checklist[blockId]?.[i] ?? item.checked ?? false,
          change: (change: boolean) =>
            setState?.({
              checklist: { ...state?.checklist, [blockId]: stateWith(blockId, i, change, checklistBlockData) },
            }),
        })),
      },
    };
  }

  function makeTextboxBlockData(block: OutputBlockData<string, any>) {
    const textboxBlockData = block.data as TextBoxBlockData;
    const blockId = block.id || "";

    return {
      ...block,
      data: {
        ...textboxBlockData,
        default: state?.textbox[blockId] ?? textboxBlockData.default ?? "",
        change: (newVal: string) =>
          setState?.({
            textbox: { ...state?.textbox, [blockId]: newVal },
          }),
      },
    };
  }

  data.blocks = data.blocks.map((block) => {
    const blockId = block.id;
    if (!blockId || !state) return block;

    if (block.type === "checklist") {
      return makeChecklistBlockData(block);
    }

    if (block.type === "textbox") {
      return makeTextboxBlockData(block);
    }

    return block;
  });

  return (
    <div className="block-renderer" style={{ maxWidth: "100vw" }}>
      <Blocks
        data={data as DataProp}
        config={config}
        renderers={{
          textbox: TextBoxRenderer,
          image: ImageRenderer,
          qanda: QandARenderer,
          checklist: ChecklistRenderer,
          ...renderers,
        }}
      />
    </div>
  );
};
