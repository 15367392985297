import {
  Alert,
  Flex,
  Text,
  TextAreaField,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  View,
} from "@aws-amplify/ui-react";
import styled from "@emotion/styled";
import { Question, QuestionSide } from "types/Question";

interface Props {
  question: Question | Omit<Question, "id">;
  updateQuestion: (update: Partial<Omit<Question, "id">>) => void;
  labelPost?: string;
  sidePosts?: Array<string>;
}

export const FeelingsQuestionEditor: React.FC<Props> = ({ question, updateQuestion, labelPost, sidePosts }) => {
  const updateSide = (index: number, update: Partial<QuestionSide>) => {
    updateQuestion({
      sides: question.sides.map((s, i) => (i === index ? { ...s, ...update } : s)),
    });
  };

  const sidedness = question.sides[0].disabled ? "right" : question.sides[1].disabled ? "left" : "both";

  const updateSidesChanged = (val: string | (string | undefined)[] | undefined) => {
    if (Array.isArray(val)) {
      val = val[0];
    }
    if (val === undefined) return;

    const newSides = structuredClone(question.sides);
    if (val === "left") {
      newSides[0].disabled = false;
      newSides[1].disabled = true;
    } else if (val === "right") {
      newSides[0].disabled = true;
      newSides[1].disabled = false;
    } else {
      newSides[0].disabled = false;
      newSides[1].disabled = false;
    }
    updateQuestion({
      sides: newSides,
    });
  };

  return (
    <View>
      <View style={{ position: "relative" }}>
        {labelPost && (
          <UnderTextField isDisabled label="" value={`${question.label} ${labelPost}`} onChange={() => {}} />
        )}
        <TextField
          label="Value Label"
          value={question.label}
          onChange={(e) => updateQuestion({ label: e.target.value })}
        />
      </View>
      <Text marginTop="1rem">Sides</Text>
      <Alert>
        For each value you can allow users to answer using a two-sided or one-sided slider. A two-sided slider asks the
        user to reflect on the trade-offs between the two options. A one-sided slider asks how important a reason for
        the given option is.
      </Alert>
      <ToggleButtonGroup justifyContent="center" isExclusive value={sidedness} onChange={updateSidesChanged}>
        <ToggleButton value="left">Left Side only</ToggleButton>
        <ToggleButton value="both">Both Sides</ToggleButton>
        <ToggleButton value="right">Right Side only</ToggleButton>
      </ToggleButtonGroup>
      <Text marginTop="1rem">Option texts</Text>
      <Flex>
        {question.sides.map((side, index) => (
          <GrowView key={index}>
            {sidePosts && (
              <UnderTextArea isDisabled label="" value={`${side.title} ${sidePosts[index]}`} onChange={() => {}} />
            )}
            <TextAreaField
              labelHidden
              isDisabled={side.disabled}
              label={`Side ${index + 1} Text`}
              value={side.title}
              onChange={(e) => updateSide(index, { title: e.target.value })}
            />
          </GrowView>
        ))}
      </Flex>
      <Text>Slider Labels</Text>
      <Flex>
        <GrowTextField
          label={`Side 1 Slider Label`}
          isDisabled={question.sides[0].disabled}
          labelHidden
          value={question.sides[0].label}
          onChange={(e) => updateSide(0, { label: e.target.value })}
        />
        <GrowTextField
          label="Zero Label"
          labelHidden
          value={question.zeroLabel}
          onChange={(e) => updateQuestion({ zeroLabel: e.target.value })}
        />
        <GrowTextField
          label={`Side 2 Slider Label`}
          isDisabled={question.sides[1].disabled}
          labelHidden
          value={question.sides[1].label}
          onChange={(e) => updateSide(1, { label: e.target.value })}
        />
      </Flex>
    </View>
  );
};

const GrowView = styled(View)`
  flex-grow: 1;
  position: relative;
`;

const GrowTextField = styled(TextField)`
  flex-grow: 1;
`;

const UnderTextField = styled(TextField)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  .amplify-input[disabled] {
    background-color: white;
  }
`;

const UnderTextArea = styled(TextAreaField)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  .amplify-textarea[disabled] {
    background-color: white;
  }
`;
