import { PageEditorProps } from ".";
import { KnowledgePage } from "../../../types/Page";
import { EditorBlock } from "../../../components/editor";

export const KnowledgePageEditor: React.FC<PageEditorProps<KnowledgePage>> = ({ page, onUpdate }) => {
  return (
    <EditorBlock
      data={page.blocks}
      onChange={(newBlocks) => onUpdate({ blocks: newBlocks })}
      holder={`knowledge-editor-preamble-container`}
      id={page.id}
    />
  );
};
