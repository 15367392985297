import { Alert, Button, Flex, Heading, Icon, TabItem, Tabs, Text, TextField, View } from "@aws-amplify/ui-react";
import { PageEditorProps } from "../";
import { FeelingsPage } from "types/Page";
import { EditorBlock } from "components/editor";
import styled from "@emotion/styled";
import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { SortableToggleMenuButton } from "components/SortableToggleMenuButton";
import { MdInfo, MdOutlineAddCircleOutline } from "react-icons/md";
import { useMemo, useState } from "react";
import { Question } from "types/Question";
import { FeelingsQuestionEditor } from "./FeelingsQuestionEditor";
import { createNewQuestion } from "types/DecisionAid";
import { HiEllipsisHorizontalCircle } from "react-icons/hi2";

export const FeelingsPageEditor: React.FC<PageEditorProps<FeelingsPage>> = ({ aid, page, onUpdate, onUpdateAid }) => {
  const sensors = useSensors(useSensor(PointerSensor));
  const [newQuestionLabel, setNewQuestionLabel] = useState<string>("");
  const [currentQuestionId, setCurrentQuestionId] = useState<string>();

  const currentQuestion = useMemo<Question | undefined>(() => {
    if (currentQuestionId !== undefined) {
      return page.questions.find((q) => q.id === currentQuestionId);
    }
    return undefined;
  }, [currentQuestionId, page.questions]);

  const addNewQuestion = () => {
    const newQuestion = createNewQuestion(aid, newQuestionLabel, false);
    onUpdate({
      questions: [...page.questions, newQuestion],
    });
    setNewQuestionLabel("");
  };

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = page.questions.findIndex((q) => q.id === active.id);
      const newIndex = page.questions.findIndex((q) => q.id === over.id);
      onUpdate({
        questions: arrayMove(page.questions, oldIndex, newIndex),
      });
    }
  }

  const deleteQuestion = (id: string) => {
    onUpdate({
      questions: page.questions.filter((q) => q.id !== id),
    });
    if (id === currentQuestionId) {
      setCurrentQuestionId(undefined);
    }
  };

  const updateCurrentQuestion = (update: Partial<Question>) => {
    onUpdate({
      questions: page.questions.map((q) => {
        delete q.isExtra;
        return q.id === currentQuestionId ? { ...q, ...update } : q;
      }),
    });
  };

  return (
    <>
      <Tabs>
        <TabItem
          title={
            <Text>
              <Icon as={MdInfo} /> Intro Page
            </Text>
          }
        >
          <Heading level={4}>Intro Text</Heading>
          <Alert>
            This text shows when users first see the page. Give your users brief instructions for the 'Your feelings'
            section here.
          </Alert>
          <EditorBlock
            data={page.preamble}
            onChange={(newBlocks) => onUpdate({ preamble: newBlocks })}
            holder="feelings-page-pre-container"
          />
        </TabItem>

        <TabItem title="Values">
          <Heading level={4}>Values</Heading>
          <Alert>
            Create a list of values related to the two options. Then, click on each value to add the reasons for
            choosing each option.
          </Alert>
          <Flex>
            <Sidebar>
              <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                <SortableContext items={page.questions} strategy={verticalListSortingStrategy}>
                  {page.questions.map((question) => (
                    <SortableToggleMenuButton
                      key={question.id}
                      id={question.id}
                      onDelete={deleteQuestion.bind(null, question.id)}
                      name={question.label}
                      onClick={() => setCurrentQuestionId(question.id)}
                      active={question.id === currentQuestionId}
                    />
                  ))}
                </SortableContext>
              </DndContext>
              <View>
                <TextField
                  label="Add Value"
                  placeholder="Short Label"
                  value={newQuestionLabel}
                  onChange={(e) => setNewQuestionLabel(e.target.value)}
                  outerEndComponent={
                    <Button onClick={addNewQuestion} disabled={newQuestionLabel === ""}>
                      <Icon as={MdOutlineAddCircleOutline} />
                    </Button>
                  }
                />
              </View>
            </Sidebar>
            <GrowView>
              {currentQuestion ? (
                <FeelingsQuestionEditor question={currentQuestion} updateQuestion={updateCurrentQuestion} />
              ) : (
                <View>No Value Selected</View>
              )}
            </GrowView>
          </Flex>
        </TabItem>

        <TabItem title="Default New Value">
          <Heading level={4}>Default new Value</Heading>
          <Alert>
            Users can add their own values and reasons related to the decision. Here you can set the default text that
            users will see when they add a new value.
          </Alert>
          <FeelingsQuestionEditor
            question={aid.defaultNewQuestion}
            labelPost=" <#>"
            sidePosts={aid.options.map((opt) => opt.name)}
            updateQuestion={(update) => onUpdateAid({ defaultNewQuestion: { ...aid.defaultNewQuestion, ...update } })}
          />
        </TabItem>

        <TabItem
          title={
            <Text>
              <Icon as={HiEllipsisHorizontalCircle} marginRight="0.25rem" />
              Outro Page
            </Text>
          }
        >
          <Heading level={4}>Outro Text</Heading>
          <Alert>
            This section appears after all of the pre-set values are completed. The user can then add a new value or
            continue to the "Your decision" section. This text should provide instructions to the user.
          </Alert>
          <EditorBlock
            data={page.post}
            onChange={(newBlocks) => onUpdate({ post: newBlocks })}
            holder="feelings-page-post-container"
          />
        </TabItem>
      </Tabs>
    </>
  );
};

const Sidebar = styled(View)`
  min-width: 250px;
  max-width: 250px;
`;

const GrowView = styled(View)`
  flex-grow: 1;
`;
