/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Badge,
  Button,
  Card,
  CheckboxField,
  Flex,
  Grid,
  Icon,
  SliderField,
  Text,
  TextAreaField,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import styled from "@emotion/styled";
import { colourForIndex } from "components/ColouredButtons";
import { MdDelete, MdEdit } from "react-icons/md";
import { Question, QuestionSide } from "types/Question";
import { DecisionAidState } from "types/DecisionAid";

const SLIDER_MAX = 100;

interface Props {
  question: Question;
  index: number;
  onDelete: (q: Question) => void;
  questionValues: DecisionAidState["questionValues"];
  state?: DecisionAidState;
  updateState?: (update: Partial<DecisionAidState>) => void;
  setQuestionValues: React.Dispatch<React.SetStateAction<DecisionAidState["questionValues"]>>;
}

export const QuestionRenderer: React.FC<Props> = ({
  question,
  index,
  onDelete,
  questionValues,
  state,
  updateState,
  setQuestionValues,
}) => {
  function fromSliderValue(val: number, question: Question) {
    return (val * (question.sides[1].value - question.sides[0].value)) / SLIDER_MAX + question.sides[0].value;
  }

  function overrideSide(id: string, side: number, update: Partial<QuestionSide>) {
    if (!state || !updateState) return;

    const extraIndex = state.extraQuestions.findIndex((q) => q.id === id);
    if (extraIndex >= 0) {
      const extraQuestions = [...state.extraQuestions];
      const sides = [...state.extraQuestions[extraIndex].sides];
      sides[side] = { ...sides[side], ...update };
      extraQuestions[extraIndex] = {
        ...extraQuestions[extraIndex],
        sides,
      };
      updateState({
        extraQuestions,
      });
      return;
    }

    const sides = state?.questionOverrides[id]?.sides || [{}, {}];
    sides[side] = { ...sides[side], ...update };
    updateState({
      questionOverrides: {
        ...state?.questionOverrides,
        [id]: {
          ...state?.questionOverrides[id],
          sides,
        },
      },
    });
  }

  function setQuestionValue(sliderVal: number, question: Question) {
    const newVals = {
      [question.id]: fromSliderValue(sliderVal, question),
    };
    setQuestionValues((cVals) => ({ ...cVals, ...newVals }));
  }

  function overrideExtraQuestion(id: string, update: Partial<Question>) {
    if (!state || !updateState) return;
    const extraIndex = state.extraQuestions.findIndex((q) => q.id === id);
    if (extraIndex >= 0) {
      const extraQuestions = [...state.extraQuestions];
      extraQuestions[extraIndex] = {
        ...extraQuestions[extraIndex],
        ...update,
      };
      updateState({ extraQuestions });
    }
  }

  function sliderLeftPercentage(question: Question): number {
    const baseValue = questionValues[question.id] < 0 ? toSliderValue(questionValues[question.id], question) : 50;

    if (question.sides[0].disabled) {
      return (baseValue - 50) * 2;
    }
    if (question.sides[1].disabled) {
      return baseValue * 2;
    }

    return baseValue;
  }

  function toSliderValue(val: number, question: Question): number {
    return ((val - question.sides[0].value) * SLIDER_MAX) / (question.sides[1].value - question.sides[0].value);
  }

  function sliderRightPercentage(question: Question): number {
    const baseValue =
      questionValues[question.id] >= 0 ? 100 - toSliderValue(questionValues[question.id], question) : 50;

    if (question.sides[0].disabled) {
      return baseValue * 2;
    }

    if (question.sides[1].disabled) {
      return (baseValue - 50) * 2;
    }

    return baseValue;
  }

  return (
    <>
      <ChoiceCard variation="outlined">
        <ChoiceBadge backgroundColor={colourForIndex(index, "20")}>
          {question.isExtra ? (
            <BadgeInput
              label=""
              variation="quiet"
              size="small"
              labelHidden
              value={question.label}
              onChange={(e) => overrideExtraQuestion(question.id, { label: e.target.value })}
            />
          ) : (
            question.label
          )}
        </ChoiceBadge>
        <DeleteButton variation="warning" size="small" onClick={() => onDelete(question)}>
          <Icon as={MdDelete} />
        </DeleteButton>
        <Grid autoColumns="48%" gap="4%" autoFlow="column">
          <SideTextContainer>
            {question.isExtra && (
              <CheckboxField
                label=""
                name=""
                value=""
                isDisabled={question.sides[1].disabled}
                checked={!question.sides[0].disabled}
                onChange={(e) => overrideSide(question.id, 0, { disabled: !e.target.checked })}
              />
            )}
            <ExpandableTextContainer
              css={css`
                ${question.sides[0].disabled && "opacity: 0;"}
              `}
            >
              <ExpandableTextUnderlay>{question.sides[0].title}</ExpandableTextUnderlay>
              <EditIcon as={MdEdit} />
              <SliderSideText
                isDisabled={question.sides[0].disabled}
                onChange={(e) => overrideSide(question.id, 0, { title: e.target.value.replaceAll("\n", "") })}
                label={""}
                labelHidden
                variation="quiet"
                value={question.sides[0].title}
              />
            </ExpandableTextContainer>
          </SideTextContainer>
          <SideTextContainer>
            {question.isExtra && (
              <CheckboxField
                label=""
                name=""
                value=""
                isDisabled={question.sides[0].disabled}
                checked={!question.sides[1].disabled}
                onChange={(e) => overrideSide(question.id, 1, { disabled: !e.target.checked })}
              />
            )}
            <ExpandableTextContainer
              css={css`
                ${question.sides[1].disabled && "opacity: 0;"}
              `}
            >
              <ExpandableTextUnderlay>{question.sides[1].title}</ExpandableTextUnderlay>
              <EditIcon as={MdEdit} />
              <SliderSideText
                isDisabled={question.sides[1].disabled}
                onChange={(e) => overrideSide(question.id, 1, { title: e.target.value.replaceAll("\n", "") })}
                label={""}
                labelHidden
                variation="quiet"
                value={question.sides[1].title}
              />
            </ExpandableTextContainer>
          </SideTextContainer>
        </Grid>
        <Flex direction="row" gap="0" justifyContent="stretch">
          {question.sides[0].disabled && (
            <Spacer
              css={css`
                ::before {
                  margin-right: -1em;
                }
              `}
            />
          )}
          <SliderField
            css={css`
              z-index: 1;
              .amplify-sliderfield__range {
                left: ${sliderLeftPercentage(question)}% !important;
                right: ${sliderRightPercentage(question)}% !important;
              }
            `}
            basis="calc(var(--amplify-components-sliderfield-thumb-height) / 4)"
            grow="1"
            label={question.label}
            value={toSliderValue(questionValues[question.id], question)}
            onChange={(val) => setQuestionValue(val, question)}
            labelHidden
            filledTrackColor={colourForIndex(index, "60")}
            min={question.sides[0].disabled ? SLIDER_MAX / 2 : 0}
            max={question.sides[1].disabled ? SLIDER_MAX / 2 : SLIDER_MAX}
          />
          {question.sides[1].disabled && (
            <Spacer
              css={css`
                ::before {
                  margin-left: -1em;
                }
              `}
            />
          )}
        </Flex>
        <EqualText justifyContent="space-between">
          <Text textAlign="left">{!question.sides[0].disabled && question.sides[0].label}</Text>
          <Text textAlign="center">{question.zeroLabel}</Text>
          <Text textAlign="right">{!question.sides[1].disabled && question.sides[1].label}</Text>
        </EqualText>
      </ChoiceCard>
    </>
  );
};

const SideTextContainer = styled(View)`
  .amplify-checkboxfield {
    align-items: center;
  }
`;

const EqualText = styled(Flex)`
  p {
    flex-basis: 0;
    flex-grow: 1;
  }
`;

const Spacer = styled(Flex)`
  flex-basis: 0;
  flex-grow: 1;
  align-items: center;
  ::before {
    z-index: 0;
    position: relative;
    height: var(--amplify-components-sliderfield-track-height);
    border-radius: calc(var(--amplify-components-sliderfield-track-height) / 2);
    width: 100%;
    content: "";
    background: var(--amplify-colors-neutral-40);
    flex-grow: 1;
  }
`;

const EditIcon = styled(Icon)`
  position: absolute;
  bottom: 0.2em;
  right: 0.2em;
  color: var(--amplify-colors-neutral-40);
`;

const ExpandableTextUnderlay = styled(Text)`
  font-size: var(--amplify-components-fieldcontrol-font-size);
  line-height: var(--amplify-components-fieldcontrol-line-height);
  padding-block-start: var(--amplify-components-fieldcontrol-padding-block-start);
  padding-block-end: var(--amplify-components-fieldcontrol-padding-block-end);
  padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start);
  padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-end);
  color: transparent;
`;

const SliderSideText = styled(TextAreaField)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  & .amplify-textarea {
    height: 100%;
    overflow: hidden;
  }
`;

const ExpandableTextContainer = styled(View)`
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  position: relative;

  @media (max-width: 500px) {
    --amplify-components-fieldcontrol-font-size: 0.8rem;

    p,
    .amplify-textarea {
      padding: 0.25rem;
    }
  }
`;

const ChoiceBadge = styled(Badge)`
  position: absolute;
  left: 0em;
  top: 0;
`;

const ChoiceCard = styled(Card)`
  padding-top: 1.5em;
`;

const BadgeInput = styled(TextField)`
  margin: -0.2em;
  & input {
    padding: 0;
  }
`;

const DeleteButton = styled(Button)`
  position: absolute;
  right: 0em;
  top: 0;
  padding: 0.5em;
  z-index: 1;
  --amplify-components-button-warning-background-color: white;

  svg {
    z-index: 2;
  }
`;
