import { Button, Flex, Grid, Text, View } from "@aws-amplify/ui-react";
import { PageRendererProps } from "../";
import { FeelingsPage } from "types/Page";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { DecisionAidState, createNewQuestion } from "types/DecisionAid";
import { Question } from "types/Question";
import { ReasonsGraph } from "components/ReasonsGraph";
import { useAllQuestions } from "hooks/useAllQuestions";
import { useConfirmModal } from "components/ConfirmModal";
import { FeelingsPageNav } from "./FeelingsPageNav";
import { BlockRenderer } from "components/editor/tools/BlockRenderer";
import { QuestionRenderer } from "./QuestionRenderer";

export const FeelingsPageRenderer: React.FC<PageRendererProps<FeelingsPage>> = ({
  aid,
  page,
  state,
  updateState,
  nextPage,
  prevPage,
}) => {
  const [currentQuestionId, setCurrentQuestionId] = useState<number>(-1);

  const [questionValues, setQuestionValues] = useState<DecisionAidState["questionValues"]>(
    page.questions.reduce((current, q) => ({ ...current, [q.id]: state?.questionValues[q.id] || 0 }), {})
  );

  const combinedQuestions = useAllQuestions(page.questions, state);

  const [DeleteModal, openDeleteModal] = useConfirmModal<Question>((q) => q && deleteQuestion(q.id), "Delete");

  function onDeletePressed(q: Question) {
    openDeleteModal(<Text>Are you sure you want to delete this value?</Text>, q);
  }

  useEffect(() => {
    updateState && updateState({ questionValues });
  }, [updateState, questionValues]);

  function deleteQuestion(id: string) {
    if (!state || !updateState) return;

    const question = combinedQuestions.find((q) => q.id === id);
    if (!question) return;

    if (question.isExtra) {
      updateState({ extraQuestions: state.extraQuestions.filter((q) => q.id !== id) });
    } else {
      updateState({ deletedQuestions: [...state.deletedQuestions, id] });
    }
  }

  function addQuestion() {
    if (!updateState) return;

    const newQuestion = createNewQuestion(aid, (state?.extraQuestions.length || 0) + 1, true);

    setQuestionValues({ ...questionValues, [newQuestion.id]: 0 });
    updateState({
      extraQuestions: [...(state?.extraQuestions || []), newQuestion],
      questionValues: {
        ...state?.questionValues,
        [newQuestion.id]: 0,
      },
    });
  }

  return (
    <ContainedView>
      {DeleteModal}

      <ChoicesContainer>
        <ChoicesInner style={{ left: `${-105 * (currentQuestionId + 1)}%` }}>
          <BlockChoice>
            <BlockRenderer data={page.preamble} />
          </BlockChoice>

          {combinedQuestions.map((question, i) => (
            <Choice key={question.id}>
              <QuestionRenderer
                state={state}
                updateState={updateState}
                questionValues={questionValues}
                setQuestionValues={setQuestionValues}
                question={question}
                index={i}
                onDelete={onDeletePressed}
              />
            </Choice>
          ))}

          <Pane>
            <BlockRenderer data={page.post} />
            <Flex direction="column">
              <Button onClick={addQuestion}>Add Another Value</Button>
              <Button variation="primary" onClick={nextPage}>
                Continue
              </Button>
            </Flex>
          </Pane>
        </ChoicesInner>
      </ChoicesContainer>

      <FeelingsPageNav
        selected={currentQuestionId}
        setSelected={setCurrentQuestionId}
        questions={combinedQuestions}
        nextPage={nextPage}
        prevPage={prevPage}
      />

      {currentQuestionId !== -1 && (
        <ReasonsGraph
          state={state}
          selectQuestion={setCurrentQuestionId}
          selectedIndex={currentQuestionId}
          values={questionValues}
          questions={combinedQuestions}
          aid={aid}
        />
      )}
    </ContainedView>
  );
};

const ContainedView = styled(Flex)`
  flex-direction: column;
  container-name: feelings-page;
  container-type: size;
  height: 100%;
`;

const BlockChoice = styled(View)`
  min-width: 100%;
  max-width: 100%;
  min-width: 100cqw;
  max-width: 100cqw;
  box-sizing: border-box;
  position: relative;
  overflow-y: scroll;
`;

const Pane = styled(BlockChoice)`
  padding: 0 0.5em;
  padding-top: 1em;
`;

const Choice = styled(Pane)`
  @media (max-height: 800px) {
    font-size: 0.9em;
  }

  @media (max-height: 700px) {
    font-size: 0.8em;
  }
`;

const ChoicesContainer = styled(View)`
  flex-shrink: 1;
  overflow: hidden;
  container-type: inline-size;
  position: relative;
`;

const ChoicesInner = styled(Grid)`
  grid-auto-columns: 100%;
  grid-auto-columns: 100cqw;
  height: 100%;
  grid-auto-flow: column;
  column-gap: 5%;
  column-gap: 5cqw;
  flex-direction: row;
  position: relative;
  transition: left 600ms ease-in-out;
`;
