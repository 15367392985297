import { ReactNode } from "react";
import { DecisionAid, DecisionAidState } from "../../../types/DecisionAid";
import { DecisionAidPage, DecisionPage, FeelingsPage, KnowledgePage } from "../../../types/Page";
import { KnowledgePageRenderer } from "./KnowledgePageRenderer";
import { FeelingsPageRenderer } from "./FeelingsPageRenderer";
import { DecisionPageRenderer } from "./DecisionPageRenderer";
import { useParams } from "react-router-dom";

interface RendererPropsBase {
  aid: DecisionAid;
  state?: DecisionAidState;
  prevPage?: () => void;
  nextPage?: () => void;
  pageIndex: number;
}

interface RendererProps extends RendererPropsBase {
  updateState?: (update: Partial<DecisionAidState>) => void;
}

export interface PageRendererProps<TPage extends DecisionAidPage> extends RendererProps {
  page: TPage;
}

type PageRenderers = {
  [PageType in DecisionAidPage as PageType["type"]]: (props: PageRendererProps<DecisionAidPage>) => ReactNode;
};

const Renderers: PageRenderers = {
  knowledge: ({ page, ...props }) => <KnowledgePageRenderer page={page as KnowledgePage} {...props} />,
  decision: ({ page, ...props }) => <DecisionPageRenderer page={page as DecisionPage} {...props} />,
  feelings: ({ page, ...props }) => <FeelingsPageRenderer page={page as FeelingsPage} {...props} />,
};

interface Props extends RendererPropsBase {
  page?: DecisionAidPage;
  updateState?: (update: Partial<DecisionAidState>) => void;
}

export const PageRenderer: React.FC<Props> = ({ page, aid, ...props }) => {
  const { pageid } = useParams();
  page = page || aid.pages.find((p) => p.id === pageid);

  if (!page) return <></>;

  return <>{Renderers[page.type]({ page, aid, ...props })}</>;
};
